import DashboardLayout from "@/pages/Dashboard/Layout/DashboardLayout.vue";
import AuthLayout from "@/pages/Dashboard/Pages/AuthLayout.vue";

// Dashboard pages
import Dashboard from "@/pages/Dashboard/Dashboard.vue";

// Pages
const User = () => import("@/pages/Dashboard/Pages/UserProfile.vue");
const Pricing = () => import("@/pages/Dashboard/Pages/Pricing.vue");
const TimeLine = () => import("@/pages/Dashboard/Pages/TimeLinePage.vue");
const RtlSupport = () => import("@/pages/Dashboard/Pages/RtlSupport.vue");
const Login = () => import("@/pages/Dashboard/Pages/Login.vue");
const Register = () => import("@/pages/Dashboard/Pages/Register.vue");
const Lock = () => import("@/pages/Dashboard/Pages/Lock.vue");

// Components pages
const Buttons = () => import("@/pages/Dashboard/Components/Buttons.vue");
const GridSystem = () => import("@/pages/Dashboard/Components/GridSystem.vue");
const Panels = () => import("@/pages/Dashboard/Components/Panels.vue");
const SweetAlert = () => import("@/pages/Dashboard/Components/SweetAlert.vue");
const Notifications = () =>
    import("@/pages/Dashboard/Components/Notifications.vue");
const Icons = () => import("@/pages/Dashboard/Components/Icons.vue");
const Typography = () => import("@/pages/Dashboard/Components/Typography.vue");

// Forms pages
const RegularForms = () => import("@/pages/Dashboard/Forms/RegularForms.vue");
const ExtendedForms = () => import("@/pages/Dashboard/Forms/ExtendedForms.vue");
const ValidationForms = () =>
    import("@/pages/Dashboard/Forms/ValidationForms.vue");
const Wizard = () => import("@/pages/Dashboard/Forms/Wizard.vue");

// TableList pages
const RegularTables = () =>
    import("@/pages/Dashboard/Tables/RegularTables.vue");
const ExtendedTables = () =>
    import("@/pages/Dashboard/Tables/ExtendedTables.vue");
const PaginatedTables = () =>
    import("@/pages/Dashboard/Tables/PaginatedTables.vue");

// Maps pages
const GoogleMaps = () => import("@/pages/Dashboard/Maps/GoogleMaps.vue");
const FullScreenMap = () => import("@/pages/Dashboard/Maps/FullScreenMap.vue");
const VectorMaps = () => import("@/pages/Dashboard/Maps/VectorMaps.vue");

// Calendar
const Calendar = () => import("@/pages/Dashboard/Calendar.vue");

// My Plan
const DetailsPlan = () => import("@/pages/Dashboard/MyPlan/DetailsPlan.vue");
const CancelPlan = () => import("@/pages/Dashboard/MyPlan/CancelPlan.vue");
const UpgradePlan = () => import("@/pages/Dashboard/MyPlan/UpgradePlan.vue");
const Plans = () => import("@/pages/Dashboard/MyPlan/Plans.vue");
const Payment = () => import("@/pages/Dashboard/MyPlan/Payment.vue");

// Report pages
const ReportAudience = () =>
    import("@/pages/Dashboard/Reports/ReportAudience.vue");
const ReportVotedSong = () =>
    import("@/pages/Dashboard/Reports/ReportVotedSong.vue");
const ReportVotedSongPlataform = () =>
    import("@/pages/Dashboard/Reports/ReportVotedSongPlataform.vue");
const ReportRequestedSongs = () =>
    import("@/pages/Dashboard/Reports/ReportRequestedSongs.vue");
const AccessPlaylist = () =>
    import("@/pages/Dashboard/Reports/ReportPlaylist.vue");
const ReportPushNotificationListeners = () =>
    import("@/pages/Dashboard/Reports/ReportPushNotificationListeners.vue");

const ReportPushNotificationOpenings = () =>
    import("@/pages/Dashboard/Reports/ReportPushNotificationOpenings.vue");

// Playlist
const UploadMusic = () => import("@/pages/Dashboard/Playlist/UploadMusic.vue");
const CreatePlaylist = () =>
    import("@/pages/Dashboard/Playlist/CreatePlaylist.vue");

import MusicalAlert from "@/pages/Dashboard/MusicalAlert.vue";
import PushNotification from "@/pages/Dashboard/PushNotification";
import MessageSchedulling from "@/pages/Dashboard/MessageSchedulling";
import MusicPlayed from "@/pages/Dashboard/MusicPlayed";
import RequestedSongs from "@/pages/Dashboard/RequestedSongs";
import Gifts from "@/pages/Dashboard/GiftsIndex";
import GeneralGifts from "@/pages/Dashboard/GeneralGiftsIndex";
import VotedSongs from "@/pages/Dashboard/VotedSongs";
import ExtractArtist from "@/pages/Dashboard/ExtractArtist";
import ExtractMusical from "@/pages/Dashboard/ExtractMusical";
import Schedule from "@/pages/Dashboard/Schedule";
import Tracking from "@/pages/Dashboard/Tracking";
import Streaming from "@/pages/Dashboard/Streaming";
import EditRadio from "@/pages/Dashboard/Configuration/EditRadio";
import InfoPlan from "@/pages/Dashboard/Configuration/InfoPlan";
import BlockService from "@/pages/Dashboard/Block";
import PrayerRequests from "@/pages/Dashboard/PrayerRequests";
import Notfound from "@/pages/Dashboard/NotFound";
import Podcasts from "@/pages/Dashboard/Podcasts/Index";
import Geofence from "@/pages/Dashboard/Geofence/Index";
import PopupWarnings from "@/pages/Dashboard/PopupWarnings/Index";
import MobAds from "@/pages/Dashboard/MobAds/Index"
import Zones from "@/pages/Dashboard/MobAds/Zone/Index"
import SpecialCover from '@/pages/Dashboard/SpecialCover/Index'
import Guard from "./guard";

let componentsMenu = {
    path: "/components",
    component: DashboardLayout,
    redirect: "/components/buttons",
    name: "Components",
    children: [
        {
            path: "buttons",
            name: "Buttons",
            components: {
                default: Buttons
            }
        },
        {
            path: "grid-system",
            name: "Grid System",
            components: {
                default: GridSystem
            }
        },
        {
            path: "panels",
            name: "Panels",
            components: {
                default: Panels
            }
        },
        {
            path: "sweet-alert",
            name: "Sweet Alert",
            components: {
                default: SweetAlert
            }
        },
        {
            path: "notifications",
            name: "Notifications",
            components: {
                default: Notifications
            }
        },
        {
            path: "icons",
            name: "Icons",
            components: {
                default: Icons
            }
        },
        {
            path: "typography",
            name: "Typography",
            components: {
                default: Typography
            }
        }
    ]
};
let formsMenu = {
    path: "/forms",
    component: DashboardLayout,
    redirect: "/forms/regular",
    name: "Forms",
    children: [
        {
            path: "regular",
            name: "Regular Forms",
            components: {
                default: RegularForms
            }
        },
        {
            path: "extended",
            name: "Extended Forms",
            components: {
                default: ExtendedForms
            }
        },
        {
            path: "validation",
            name: "Validation Forms",
            components: {
                default: ValidationForms
            }
        },
        {
            path: "wizard",
            // name: 'Novo Cadastro',
            components: {
                default: Wizard
            }
        }
    ]
};
let reportsMenu = {
    path: "/reports",
    component: DashboardLayout,
    redirect: "/reports/report-audience",
    name: "Relatórios",
    children: [
        {
            path: "/report-sessions",
            name: "Audiência",
            components: {
                default: ReportAudience
            }
        },
        {
            path: "/report-push-notification-listeners",
            name: "Relatório de ouvintes de notificações",
            components: {
                default: ReportPushNotificationListeners
            }
        },
        {
            path: "/report-push-notification-openings",
            name: "Relatório de abertura de push",
            components: {
                default: ReportPushNotificationOpenings
            }
        },
        {
            path: "/report-voted-song",
            name: "Relatório Votação Musical",
            components: {
                default: ReportVotedSong
            },
            beforeEnter: Guard.login
        },
        {
            path: "/report-voted-song-plataform",
            name: "Musicas Votadas por Plataforma",
            components: {
                default: ReportVotedSongPlataform
            },
            beforeEnter: Guard.login
        },
        {
            path: "/report-requested-songs",
            name: "Relatório de pedido musical",
            components: {
                default: ReportRequestedSongs
            },
            beforeEnter: Guard.login
        },
        {
            path: "/popup-warnings",
            name: "Popups",
            components: {
                default: PopupWarnings
            }
            // beforeEnter: Guard.login
        }
    ]
};
let tablesMenu = {
    path: "/table-list",
    component: DashboardLayout,
    redirect: "/table-list/regular",
    name: "Tables",
    children: [
        {
            path: "regular",
            name: "Regular Tables",
            components: {
                default: RegularTables
            }
        },
        {
            path: "extended",
            name: "Extended Tables",
            components: {
                default: ExtendedTables
            }
        },
        {
            path: "paginated",
            name: "Pagianted Tables",
            components: {
                default: PaginatedTables
            }
        }
    ]
};
let mapsMenu = {
    path: "/maps",
    component: DashboardLayout,
    name: "Maps",
    redirect: "/maps/google",
    children: [
        {
            path: "google",
            name: "Google Maps",
            components: {
                default: GoogleMaps
            }
        },
        {
            path: "full-screen",
            name: "Full Screen Map",
            meta: {
                hideContent: true,
                hideFooter: true,
                navbarAbsolute: true
            },
            components: {
                default: FullScreenMap
            }
        },
        {
            path: "vector-map",
            name: "Vector Map",
            components: {
                default: VectorMaps
            }
        }
    ]
};
let pagesMenu = {
    path: "/pages",
    component: DashboardLayout,
    name: "Pages",
    redirect: "/pages/user",
    children: [
        {
            path: "user",
            name: "User Page",
            components: {
                default: User
            }
        },
        {
            path: "timeline",
            name: "Timeline Page",
            components: {
                default: TimeLine
            }
        },
        {
            path: "rtl",
            name: "وحة القيادة",
            meta: {
                rtlActive: true
            },
            components: {
                default: RtlSupport
            }
        }
    ]
};
let authPages = {
    path: "/",
    component: AuthLayout,
    name: "Authentication",
    children: [
        {
            path: "/login",
            name: "Login",
            component: Login
        },
        {
            path: "/register",
            name: "Register",
            component: Register
        },
        {
            path: "/pricing",
            name: "Pricing",
            component: Pricing
        },
        {
            path: "/lock",
            name: "Lock",
            component: Lock
        }
    ]
};

const routes = [
    {
        path: "/",
        redirect: "/dashboard",
        name: "Home"
    },
    componentsMenu,
    formsMenu,
    tablesMenu,
    mapsMenu,
    pagesMenu,
    authPages,
    reportsMenu,
    {
        path: "/",
        component: DashboardLayout,
        children: [
            {
                path: "dashboard",
                name: "Dashboard",
                components: {
                    default: Dashboard
                },
                beforeEnter: Guard.login
            },
            {
                path: "calendar",
                name: "Calendar",
                components: {
                    default: Calendar
                }
            },
            {
                path: "musical-alert",
                name: "Alerta Musical",
                components: {
                    default: MusicalAlert
                },
                beforeEnter: Guard.login
            },
            {
                path: "push-notifications",
                name: "Notificações push",
                components: {
                    default: PushNotification
                },
                beforeEnter: Guard.login
            },
            {
                path: "message-schedulling",
                name: "Agendamento",
                components: {
                    default: MessageSchedulling
                }
            },
            {
                path: "wd-reports",
                name: "Músicas Tocadas",
                components: {
                    default: MusicPlayed
                },
                beforeEnter: Guard.login
            },
            {
                path: "requested-songs",
                name: "Pedido Musical",
                components: {
                    default: RequestedSongs
                },
                beforeEnter: Guard.login
            },
            {
                path: "gifts",
                name: "Prêmio da hora",
                components: {
                    default: Gifts
                },
                beforeEnter: Guard.login
            },
            {
                path: "general-gifts",
                name: "Promoções",
                components: {
                    default: GeneralGifts
                },
                beforeEnter: Guard.login
            },
            {
                path: "voted-songs",
                name: "Votação Musical",
                components: {
                    default: VotedSongs
                },
                beforeEnter: Guard.login
            },
            {
                path: "extract-artist",
                name: "Extrato por Artista",
                components: {
                    default: ExtractArtist
                },
                beforeEnter: Guard.login
            },
            {
                path: "extract-musical",
                name: "Extrato Musical",
                components: {
                    default: ExtractMusical
                },
                beforeEnter: Guard.login
            },
            {
                path: "schedule",
                name: "Programação",
                components: {
                    default: Schedule
                },
                beforeEnter: Guard.login
            },
            {
                path: "tracking",
                name: "Tracking",
                components: {
                    default: Tracking
                },
                beforeEnter: Guard.login
            },
            {
                path: "dashboard-streaming",
                name: "Streaming ao Vivo",
                components: {
                    default: Streaming
                },
                beforeEnter: Guard.login
            },
            {
                path: "prayer-requests",
                name: "Pedido de Oração",
                components: {
                    default: PrayerRequests
                },
                beforeEnter: Guard.login
            },
            {
                path: "special-cover",
                name: "Capa Especial",
                components: {
                    default: SpecialCover
                },
                beforeEnter: Guard.login
            },
            {
                path: "configuration/edit",
                name: "Editar",
                components: {
                    default: EditRadio
                },
                beforeEnter: Guard.login
            },
            {
                path: "configuration/info-plan",
                name: "Informações do Plano",
                components: {
                    default: InfoPlan
                },
                beforeEnter: Guard.login
            },
            {
                path: "my-plan/upgrade-plan",
                // name: 'Atualização de Plano',
                components: {
                    default: UpgradePlan
                },
                beforeEnter: Guard.login
            },
            {
                path: "my-plan/details-plan",
                name: "Detalhes do plano",
                components: {
                    default: DetailsPlan
                },
                beforeEnter: Guard.login
            },
            {
                path: "my-plan/cancel-plan",
                name: "Cancelamento",
                components: {
                    default: CancelPlan
                },
                beforeEnter: Guard.login
            },
            {
                path: "my-plan/plans",
                // name: 'Planos',
                components: {
                    default: Plans
                },
                beforeEnter: Guard.login
            },
            {
                path: "my-plan/payment",
                // name: 'Planos',
                components: {
                    default: Payment
                },
                beforeEnter: Guard.login
            },
            {
                path: "block-service",
                name: "Serviço indisponível",
                components: {
                    default: BlockService
                }
            },
            {
                path: "playlist-upload-music",
                name: "Upload Musicas",
                components: {
                    default: UploadMusic
                },
                beforeEnter: Guard.login
            },
            {
                path: "podcasts",
                name: "Podcasts",
                components: {
                    default: Podcasts
                },
                beforeEnter: Guard.login
            },
            {
                path: "geofence",
                name: "Geofence",
                components: {
                    default: Geofence
                },
                beforeEnter: Guard.login
            },
            {
                path: "playlist",
                name: "Playlist",
                components: {
                    default: CreatePlaylist
                },
                beforeEnter: Guard.login
            },
            {
                path: "report-access-playlists",
                name: "Acessos às playlists",
                components: {
                    default: AccessPlaylist
                },
                beforeEnter: Guard.login
            },
            {
                path: "mobads",
                name: "MobAds",
                components: {
                    default: MobAds
                },
                beforeEnter: Guard.login
            },
            {
                path: "advertising-campaign-zones",
                name: "Áreas do dispositivo",
                components: {
                    default: Zones
                },
                beforeEnter: Guard.login
            },
            {
                path: "*",
                name: "Página não encontrada",
                components: {
                    default: Notfound
                }
            }
        ]
    }
];

export default routes;
