import axios from "axios";
import package_json from '../../package.json'

export default () => {
    const token = localStorage.getItem('SET_APP');
    if (process.env.NODE_ENV === "development") {
        console.log(`Versão do painel: ${package_json.version}`)
        console.log('#### Desenvolvimento ####');
        return axios.create({ baseURL: "http://localhost:3003/", headers: { 'x-access-token': token } });
    } else if (process.env.NODE_ENV === "homolog") {
        console.log(`Versão do painel: ${package_json.version}`)
        console.log('#### Homolog ####');
        return axios.create({ baseURL: "https://api.painel.homolog.mobradio.com.br/", headers: { 'x-access-token': token }  });
    }else if (process.env.NODE_ENV === "stage") {
        console.log(`Versão do painel: ${package_json.version}`)
        console.log('#### Stage ####');
        return axios.create({ baseURL: "https://api.painel.stage.mobradio.com.br/", 
        headers: {
            'x-access-token': token
        }   });
    }else{
        console.log('#### Produção ####');
        return axios.create({ baseURL: "https://api.painel.mobradio.com.br/", headers: { 'x-access-token': token }  });
    }
};
