import axios from 'axios'
import package_json from '../../package.json'

let configObject = {
    baseURL: "https://api.painel.mobradio.com.br/"
}

if (process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test") {
    console.log("SINGLETON")
    console.log(`Versão do painel: ${package_json.version}`)
    console.log('#### Desenvolvimento ####');

    configObject.baseURL = "http://localhost:3003/"

} else if (process.env.NODE_ENV === "homolog") {
    console.log("SINGLETON")
    console.log(`Versão do painel: ${package_json.version}`)
    console.log('#### Homolog ####');

    configObject.baseURL = "https://api.painel.homolog.mobradio.com.br/"
} else if (process.env.NODE_ENV === "stage") {
    console.log("SINGLETON")
    console.log(`Versão do painel: ${package_json.version}`)
    console.log('#### Stage ####');

    configObject.baseURL = "https://api.painel.stage.mobradio.com.br/"
} else {
    console.log("SINGLETON")
    console.log(`Versão do painel: ${package_json.version}`)
    console.log('#### Produção ####');

    configObject.baseURL = "https://api.painel.mobradio.com.br/"
}

const apiSingleton = axios.create(configObject)

apiSingleton.interceptors.request.use(
    config => {
      config.headers['x-access-token'] = `${localStorage.getItem('SET_APP')}`
          return config
    },
    error => {
          return Promise.reject(error)
    }
)

export default apiSingleton