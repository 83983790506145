<template>
	<md-card>
		<md-card-content>
			<div class="md-layout">
				<div class="md-layout-item md-xsmall-size-100 md-small-size-60 md-medium-size-60 md-large-size-60 md-xlarge-size-60 align-center">
					<md-field>
						<label>Nome do banner</label>
						<md-input v-model="bannerData.name"
							:disabled="isOldBanner"></md-input>
					</md-field>
					<span
						v-if="bannerData.errorEmptyField && !bannerData.name"
						class="error-span"
						>Preencha este campo</span
					>
					<md-field>
						<label>URL do banner</label>
						<md-input v-model="bannerData.url"
							:disabled="isOldBanner"></md-input>
					</md-field>
					<span
						v-if="bannerData.errorEmptyField && !bannerData.url"
						class="error-span"
						>Preencha este campo</span
					>
					<md-field>
						<label>Área do banner</label>
						<md-select v-model="bannerData.idZone" :disabled="isOldBanner">
							<md-option v-for="zone in bannerZonesToSelect" :value="zone.id" :key="zone.id">
								{{ `${zone.id} - ${zone.name}` }}
							</md-option>
						</md-select>
						
					</md-field>	
					<span
						v-if="bannerData.errorEmptyField && !bannerData.idZone"
						class="error-span"
						>Preencha este campo</span
					>				
				</div>
				<div v-if="bannerData.idZone" class="md-layout-item md-xsmall-size-100 md-small-size-40 md-medium-size-40 md-large-size-40 md-xlarge-size-40 align-center"
					style="padding: 15px; text-align: center">
					<template v-if="bannerData.image || bannerData.file">
						<img :src="bannerData.image" style="max-height: 130px; width: auto; object-fit: contain;" />
					</template>
					<template v-else>
						<img src="../../../assets/image-award.png" style="max-height: 130px; object-fit: contain;" />
					</template>

					<p>
						<span v-if="bannerData.errorEmptyField && !(bannerData.image || bannerData.file)
							" style="color: red; font-style: italic; display: block">(Selecione uma imagem)</span>
						Dimensões da imagem:
						<span style="font-style: italic">
							{{
								bannerZonesToSelect.filter(
									(zone) => zone.id === bannerData.idZone
								)[0].width
							}}px
							x
							{{
								bannerZonesToSelect.filter(
									(zone) => zone.id === bannerData.idZone
								)[0].height
							}}px
						</span>
					</p>
					<p v-if="errorUpload" style="color: red">
						{{ errorUpload }}
					</p>
					<input ref="imageUploaded" :id="'upload_' + this.$props.id" type="file" accept="image/*"
						style="display: none;" @change="loadFileAndCheckResolution($event)" />
				</div>
				<div v-else class="md-layout-item md-xsmall-size-100 md-small-size-40 md-large-size-40 md-xlarge-size-40 align-center">
					<h4 style="text-align: center;">Selecione a área do banner para escolher uma imagem</h4>
				</div>
				<div class="md-layout">
					
					<div class="md-layout-item hide-on-xsmall-screen md-small-size-60 md-medium-size-60 md-large-size-60 md-xlarge-size-60 md-xsmall-hide">
						<md-button class="md-danger" @click="deleteBanner()" v-if="!disableDelete">
							<i class="fa fa-trash"></i>
							Apagar banner
						</md-button>
					</div>
					<div
						class="md-layout-item md-xsmall-size-100 md-small-size-40 md-medium-size-40 md-large-size-40 md-xlarge-size-40">
						<label v-cloak v-if="bannerData.idZone && !isOldBanner" 
							class="md-button md-info"
							:for="'upload_' + this.$props.id">
							<div class="md-ripple">
								<i v-if="!bannerData.file" class="fa fa-plus"></i>
								<i v-else class="fa fa-edit"></i>
								{{ bannerData.file ? "Editar imagem" : "Adicionar Imagem" }}
							</div>
						</label>
					</div>
					<div class="md-layout-item md-xsmall-size-100 show-on-xsmall-screen">
						<md-button class="md-danger" @click="deleteBanner()" v-if="!disableDelete">
							<i class="fa fa-trash"></i>
							Apagar banner
						</md-button>
					</div>
				</div>
			</div>
		</md-card-content>
	</md-card>
</template>

<script>
export default {
	name: "BannerCard",
	props: ["id", "bannerData", "bannerZonesToSelect", "isOldBanner", "disableDelete"],
	data() {
		return {
			errorUpload: null,
		};
	},
	methods: {
		deleteBanner() {
			const isOldBanner = this.$props.isOldBanner;
			if (isOldBanner) {
				this.deleteOldBanner();
			} else {
				this.removeNewBannerFromList();
			}
		},
		removeNewBannerFromList() {
			this.$root.$emit("removeBannerFromList", { id: this.$props.id });
		},
		deleteOldBanner() {
			this.$root.$emit("deleteOldBanner", {
				hash: this.$props.bannerData.hash,
			});
		},
		loadFileAndCheckResolution(e) {
			let file = this.$refs.imageUploaded.files[0];
			let reader = new FileReader();
			let fileSize = file.size / 1024;

			if (fileSize >= 200) {
				this.errorUpload = "A imagem selecionada ultrapassa 200kB";
				this.bannerImage = null;
				this.fileUpload = null;
				this.$refs.imageUploaded.value = null;
				return;
			}
			reader.readAsDataURL(file);
			reader.onload = (evt) => {
				let img = new Image();
				img.onload = () => {
					const bannerZonesToSelect = this.$props.bannerZonesToSelect;
					const bannerZoneId = this.$props.bannerData.idZone;
					const bannerZone = bannerZonesToSelect.filter(
						(zone) => zone.id === bannerZoneId
					)[0];

					if (
						bannerZone.width !== img.width ||
						bannerZone.height !== img.height
					) {
						this.errorUpload =
							"A imagem selecionada não atende às dimensões estabelecidas";
						this.bannerImage = null
						this.$props.bannerData.file = null;
						this.$props.bannerData.image = null;
					} else {
						this.bannerImage = img.src;
						this.$props.bannerData.file = file;
						this.$props.bannerData.image = URL.createObjectURL(file);
						this.errorUpload = null;
					}
				};
				img.src = evt.target.result;
				this.$refs.imageUploaded.value = null;
			};

			reader.onerror = (evt) => {
				console.error(evt);
			};
		},
	},
	watch: {
		"bannerData.idZone": function () {
			this.$props.bannerData.file = null;
			this.$props.bannerData.image = null;
			this.$refs.imageUploaded.value = null;
		},
	},
};
</script>

<style scoped>
.background {
	background: #e3e3e3;
	margin: 15px 0;
	padding: 25px;
	border-radius: 10px;
}

.banner-info-container {
	display: flex;
	flex-direction: column;
	align-items: space-between;
	height: 100%;
}
.align-center { 
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 15px;
}

@media (max-width: 599px) {
	.show-on-xsmall-screen {
		display: block;
	}

	.hide-on-xsmall-screen {
		display: none;
	}
}

@media (min-width: 599px) {
	.show-on-xsmall-screen {
		display: none;
	}

	.hide-on-xsmall-screen {
		display: block;
	}
}
</style>
