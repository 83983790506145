<template>
    <div class="md-layout">
        <div v-show="loading" class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
          <div class="spinner">
              <div class="rect1"></div>
              <div class="rect2"></div>
              <div class="rect3"></div>
              <div class="rect4"></div>
              <div class="rect5"></div>
          </div>
      </div>
      <div v-show="!loading && blockStatus == 1" class="md-layout">
          <div v-for="(item, i) in totalSubscribersByTopic" :key="`A-${i}`" v-show="user !== 0 && item.topic.toLowerCase().indexOf('desenvolvimento') === -1" class="md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-50 md-large-size-33 md-xlarge-size-33">
              <md-card>
                  <md-card-header>
                      <div class="card-icon" style="background-color:#c70000;color: #fff">
                          <b>{{item.topic}}</b>
                      </div>
                      <!-- <p @click="helpCard()" class="title-card">
                          Ajuda
                          <i
                              class="fas fa-question-circle info-total-hint"
                              title="Dispositivos ativos: São os dispositivos que interagiram com o aplicativo nos últimos 6 meses e portanto receberão as notificações. Dispositivos inscritos: São os dispostivos inscritos desde o primeiro dia em que o aplicativo ficou disponível mas só os que estão em atividade receberão notificações."
                          ></i>
                      </p> -->
                  </md-card-header>
                  <md-card-content>                    
                      <table style="font-size:16px;">
                          <tbody>
                              <tr>
                                  <td>
                                      
                                  </td>
                                  <td style="text-align:center">
                                      <i class="fab fa-android icon-custom android-num"></i>
                                  </td>
                                  <td style="text-align:center">
                                      <i class="fab fa-apple icon-custom apple-num"></i>
                                  </td>
                              </tr>
                              <tr>
                                  <td>
                                      Dispositivos ativos
                                  </td>
                                  <td style="text-align:center">
                                      <div class="android-num">{{activeSubscribersByTopic[i].android_count | formatNumber}}</div>
                                  </td>
                                  <td style="text-align:center">
                                      <div class="apple-num">{{activeSubscribersByTopic[i].ios_count | formatNumber}}</div>
                                  </td>
                              </tr>
                              <!-- <tr>
                                  <td>
                                      Dispositivos inscritos
                                  </td>
                                  <td style="text-align:center">
                                      <div class="android-num">{{item.android_count | formatNumber}}</div>
                                  </td>
                                  <td style="text-align:center">
                                      <div class="apple-num">{{item.ios_count | formatNumber}}</div>
                                  </td>
                              </tr> -->
                          </tbody>
                      </table>
                  </md-card-content>
              </md-card>
          </div>
          <div v-for="(item, i) in totalSubscribersByTopic" :key="`B-${i}`"  style="width:33%;margin-right: 10px;" v-show="user == 0 && item.topic.toLowerCase().indexOf('desenvolvimento') !== -1" class="md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-50 md-large-size-33 md-xlarge-size-33">
              <md-card>
                  <md-card-content>
                      <div style="text-align:center;font-weight:800;">{{item.topic}}</div>
                      <div style="display: flex;justify-content: space-evenly;">
                          <div>
                              <i class="fab fa-android icon-custom android-num"></i>
                          </div>
                          <div>
                              <i class="fab fa-apple icon-custom apple-num"></i>
                          </div>
                      </div>
                      <div style="display: flex;justify-content: space-evenly;">
                          <div>
                              <div class="android-num">{{item.android_count | formatNumber}}</div>
                          </div>
                          <div>
                              <div class="apple-num">{{item.ios_count | formatNumber}}</div> 
                          </div>
                      </div>
                      <div style="display: flex;justify-content: space-evenly;">
                          <div>
                              <div class="android-num">{{item.android_count | formatNumber}}</div>
                          </div>
                          <div>
                              <div class="apple-num">{{item.ios_count | formatNumber}}</div> 
                          </div>
                      </div>
                  </md-card-content>
              </md-card>
          </div>
          <div v-show="user == 0 && item.topic.toLowerCase().indexOf('desenvolvimento') === -1" class="md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-50 md-large-size-33 md-xlarge-size-33">
              <md-card v-for="(item, i) in totalSubscribersByTopic" :key="`C-${i}`"  style="width:33%;margin-right: 10px;">
                  <md-card-content>
                      <div style="text-align:center;font-weight:800;">{{item.topic}}</div>
                      <div style="display: flex;justify-content: space-evenly;">
                          <div>
                              <i class="fab fa-android icon-custom android-num"></i>
                          </div>
                          <div>
                              <i class="fab fa-apple icon-custom apple-num"></i>
                          </div>
                      </div>
                      <div style="display: flex;justify-content: space-evenly;">
                          <div>
                              <div class="android-num">{{item.android_count | formatNumber}}</div>
                          </div>
                          <div>
                              <div class="apple-num">{{item.ios_count | formatNumber}}</div> 
                          </div>
                      </div>
                  </md-card-content>
              </md-card>
          </div>
  
          <md-card>
              <md-card-header class="md-card-header-icon md-card-header-blue">
                  <div class="card-icon">
                      <i class="material-icons">calendar_today</i>
                  </div>
              </md-card-header>
              <md-card-content>
                  <el-tabs v-model="activeName">
                      <el-tab-pane label="Notificações" name="first">
                          <div class="md-layout">
                              <div class="md-layout-item md-small-size-100 md-medium-size-40 md-large-size-40">
                                  <md-field>
                                      <label>Digite sua busca</label>
                                      <md-input type="text" v-model="searchTitle" v-on:keyup.enter="onEnterClick"></md-input>
                                  </md-field>
                              </div>
                              <div class="md-layout-item md-small-size-100 md-size-100 md-medium-size-20 md-large-size-15 md-xlarge-size-15">
                                  <md-button class="md-success md-block" @click="()=>{
                                        sendMixPanelEvent(`push_notifications_searched`)
                                        getPushNotifications() 
                                    }">Buscar</md-button> 
                              </div>
                              <div class="md-layout-item md-small-size-100 md-size-100 md-medium-size-20 md-large-size-15 md-xlarge-size-15">
                                  <md-button class="md-info md-block" @click="() => {
                                        dialogVisible = true;
                                        getTopic()
                                    }">Nova Notificação</md-button>                                    
                              </div>
                              <div class="md-layout-item md-small-size-100 md-size-100 md-medium-size-20 md-large-size-15 md-xlarge-size-15">
                                  <md-button class="md-warning md-block" @click="newDialogSchedulle()">Novo Agendamento</md-button>
                              </div>
                              <div class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                                    <div class="md-layout md-alignment-center-left" style="margin-top: 16px; margin-bottom: 16px;">
                                        <h4 class="md-layout-item md-size-100" style="font-weight: 400; font-size: 10pt; font-style: italic; padding-left: 0; color: #999999; margin: 0;">
                                            Última atualização às {{
                                                this.lastTimeUpdated.toLocaleTimeString("pt-BR").substring(0, 5)
                                            }}
                                        </h4>
                                    </div>
                                  <md-table :value="queriedData" :md-sort.sync="currentSort" :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort" class="paginated-table table-striped table-hover">
                                      <md-table-toolbar>
                                          <md-field>
                                              <label for="pages">Por página</label>
                                              <md-select v-model="pagination.perPage" name="pages">
                                              <md-option
                                                  v-for="item in pagination.perPageOptions"
                                                  :key="item"
                                                  :label="item"
                                                  :value="item">
                                                  {{ item }}
                                              </md-option>
                                              </md-select>
                                          </md-field>
                                      </md-table-toolbar>
  
                                      <md-table-row slot="md-table-row" slot-scope="{ item }" v-if="user == 0 && item.topic.toLowerCase().indexOf('desenvolvimento', 0) !== -1" style="background-color:#ff6e6e">
                                          <md-table-cell md-label="Título">{{ item.title }}</md-table-cell>
                                          <md-table-cell md-label="Mensagem" class="messageTable">{{ item.message }}</md-table-cell>
                                          <md-table-cell md-label="Link" class="tdLink"><div class="divLink"><a target="blank" :href="`${item.url}`">{{item.url}}</a></div></md-table-cell>
                                          <md-table-cell md-label="Status">{{ item.status }}</md-table-cell>
                                          <md-table-cell md-label="Tópico">{{ item.topic ? item.topic : "Geral" }}</md-table-cell>
                                          <md-table-cell md-label="Envios">{{ item.sent_count | formatNumber }}</md-table-cell>
                                          <md-table-cell md-label="Abertura">{{ item.count | formatNumber }}</md-table-cell>
                                          <md-table-cell md-label="Data" v-if="item.push_notification_type_id == 4">{{ new Date(item.date).toLocaleString("pt-BR") }}</md-table-cell>
                                          <md-table-cell md-label="Data" v-if="item.push_notification_type_id == 1">{{ new Date(item.date).toLocaleString("pt-BR") }}</md-table-cell>
                                          <md-table-cell md-label="" v-if="item.push_notification_type_id == 4" class="table-icon"><i class="far fa-clock" title="Mensagem agendada enviada"></i></md-table-cell>
                                          <md-table-cell md-label="" v-else class="table-icon"></md-table-cell>
                                      </md-table-row>
                                      <md-table-row slot="md-table-row" slot-scope="{ item }" v-else-if="user == 0 && item.topic.toLowerCase().indexOf('desenvolvimento', 0) === -1">
                                          <md-table-cell md-label="Título">{{ item.title }}</md-table-cell>
                                          <md-table-cell md-label="Mensagem" class="messageTable">{{ item.message }}</md-table-cell>
                                          <md-table-cell md-label="Link" class="tdLink"><div class="divLink"><a target="blank" :href="`${item.url}`">{{item.url}}</a></div></md-table-cell>
                                          <md-table-cell md-label="Status">{{ item.status }}</md-table-cell>
                                          <md-table-cell md-label="Tópico">{{ item.topic ? item.topic : "Geral" }}</md-table-cell>
                                          <md-table-cell md-label="Envios">{{ item.sent_count | formatNumber }}</md-table-cell>
                                          <md-table-cell md-label="Abertura">{{ item.count | formatNumber }}</md-table-cell>
                                          <md-table-cell md-label="Data" v-if="item.push_notification_type_id == 4">{{ new Date(item.date).toLocaleString("pt-BR") }}</md-table-cell>
                                          <md-table-cell md-label="Data" v-if="item.push_notification_type_id == 1">{{ new Date(item.date).toLocaleString("pt-BR") }}</md-table-cell>
                                          <md-table-cell md-label="" v-if="item.push_notification_type_id == 4" class="table-icon"><i class="far fa-clock" title="Mensagem agendada enviada"></i></md-table-cell>
                                          <md-table-cell md-label="" v-else class="table-icon"></md-table-cell>
                                      </md-table-row>
                                      <md-table-row slot="md-table-row" slot-scope="{ item }" v-else-if="user !== 0 && item.topic.toLowerCase().indexOf('desenvolvimento') === -1">
                                          <md-table-cell md-label="Título">{{ item.title }}</md-table-cell>
                                          <md-table-cell md-label="Mensagem" class="messageTable">{{ item.message }}</md-table-cell>
                                          <md-table-cell md-label="Link" class="tdLink"><div class="divLink"><a target="blank" :href="`${item.url}`">{{item.url}}</a></div></md-table-cell>
                                          <md-table-cell md-label="Status">{{ item.status }}</md-table-cell>
                                          <md-table-cell md-label="Tópico">{{ item.topic ? item.topic : "Geral" }}</md-table-cell>
                                          <md-table-cell md-label="Envios">{{ item.sent_count | formatNumber }}</md-table-cell>
                                          <md-table-cell md-label="Abertura">{{ item.count | formatNumber }}</md-table-cell>
                                          <md-table-cell md-label="Data" v-if="item.push_notification_type_id == 4">{{ new Date(item.date).toLocaleString("pt-BR") }}</md-table-cell>
                                          <md-table-cell md-label="Data" v-if="item.push_notification_type_id == 1">{{ new Date(item.date).toLocaleString("pt-BR") }}</md-table-cell>
                                          <md-table-cell md-label="" v-if="item.push_notification_type_id == 4" class="table-icon"><i class="far fa-clock" title="Mensagem agendada enviada"></i></md-table-cell>
                                          <md-table-cell md-label="" v-else class="table-icon"></md-table-cell>
                                      </md-table-row>
                                  </md-table>
                                  <md-card-actions md-alignment="space-between">
                                      <div class="">
                                          <p class="card-category">{{from + 1}} à {{to}} de {{total}} itens</p>
                                      </div>
                                      <pagination class="pagination-no-border pagination-success"
                                          v-model="pagination.currentPage"
                                          :per-page="pagination.perPage"
                                          :total="total">
                                      </pagination>
                                  </md-card-actions>
                              </div>
                          </div>
                      </el-tab-pane>
                      <el-tab-pane label="Agendamentos criados" name="second">
                          <div class="md-layout">
                              <div class="md-layout-item md-small-size-100 md-medium-size-40 md-large-size-40">
                                  <md-field>
                                      <label>Digite sua busca</label>
                                      <md-input type="text" v-model="searchTitleSchedule" v-on:keyup.enter="onEnterClickSchedule"></md-input>
                                  </md-field>
                              </div>
                              <div class="md-layout-item md-small-size-100 md-size-100 md-medium-size-20 md-large-size-15 md-xlarge-size-15">
                                  <md-button class="md-success md-block" @click="()=>{
                                        sendMixPanelEvent('push_notifications_scheduled_searched')
                                        getPushNotificationsSchedule()
                                    }"><i class="fas fa-search"></i> Buscar</md-button> 
                              </div>
                              <div class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                                  <md-table :value="tableSchedule" :md-sort.sync="currentSort" :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort" class="paginated-table table-striped table-hover">
                                      <md-table-toolbar>
                                          <md-field>
                                              <label for="pages">Por página</label>
                                              <md-select v-model="paginationSchedule.perPage" name="pages">
                                              <md-option
                                                  v-for="item in paginationSchedule.perPageOptions"
                                                  :key="item"
                                                  :label="item"
                                                  :value="item">
                                                  {{ item }}
                                              </md-option>
                                              </md-select>
                                          </md-field>
                                      </md-table-toolbar>
  
                                      <md-table-row slot="md-table-row" slot-scope="{ item }">
                                          <md-table-cell md-label="Título">{{ decodeURIComponent(item.title) }}</md-table-cell>
                                          <md-table-cell md-label="Mensagem" class="messageTable">{{ decodeURIComponent(item.message) }}</md-table-cell>
                                          <md-table-cell md-label="Hora de Envio">{{ new Date(item.schedule_time).toLocaleString("pt-BR").substring(0,17) }}</md-table-cell>
                                          <md-table-cell md-label="Ações">
                                              <el-button class="btn-actions" circle @click="editDialogSchedulle(item)"><i class="fas fa-edit"></i></el-button>
                                              <el-button class="btn-actions" circle id="btn-action-times" @click="deletePushSchedule(item)"><i class="far fa-times-circle"></i></el-button>
                                          </md-table-cell>
                                      </md-table-row>
                                  </md-table>
                                  <md-card-actions md-alignment="space-between">
                                      <div class="">
                                          <p class="card-category">{{fromSchedule + 1}} à {{toSchedule}} de {{totalSchedule}} itens</p>
                                      </div>
                                      <pagination class="pagination-no-border pagination-success"
                                          v-model="paginationSchedule.currentPage"
                                          :per-page="paginationSchedule.perPage"
                                          :total="totalSchedule">
                                      </pagination>
                                  </md-card-actions>
                              </div>
                          </div>
                      </el-tab-pane>
                      <el-tab-pane label="Tópicos" name="third">
                          <div class="md-layout">
                              <div class="md-layout-item md-small-size-100 md-size-100 md-medium-size-20 md-large-size-15 md-xlarge-size-15">
                                  <md-button class="md-info md-block" @click="()=> {
                                    openTopic = true;
                                    topicName = ''
                                }"><i class="fas fa-list-ul"></i> &nbsp; Novo Tópico</md-button> 
                              </div>
                              <div class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                                  <md-table :value="tableTopics" class="paginated-table table-striped table-hover">
                                      <md-table-row slot="md-table-row" slot-scope="{ item }">
                                          <md-table-cell md-label="Nome">{{ item.name }}</md-table-cell>
                                          <md-table-cell md-label="Apagar">
                                              <div class="container-btn-edit">
                                                  <div class="row-btn-edit-push">
                                                      <el-button icon="el-icon-delete" circle @click="() => {
                                                            deleteTopic(item.id)
                                                        }"></el-button>
                                                  </div>
                                              </div>
                                          </md-table-cell>
                                      </md-table-row>
                                  </md-table>
                              </div>
                          </div>
                      </el-tab-pane>
                  </el-tabs>
              </md-card-content>
          </md-card>
  
          <!-- DIALOG TOPIC - NEW -->
          <el-dialog
              title="Novo Tópico"
              @open="sendMixPanelEvent('push_notification_new_topic_form_opened')"
              @closed="sendMixPanelEvent('push_notification_new_topic_form_closed')"
              :visible.sync="openTopic"
              width="30%"
              center>
              <div style="text-align:center !important">
                  <el-form style="text-align:center">
                      <el-input v-model="topicName" style="width:50%" placeholder="Nome do tópico"></el-input>
                  </el-form>
              </div>
              <span slot="footer" class="dialog-footer">
                  <el-button @click="openTopic = false">Cancelar</el-button>
                  <el-button type="primary" @click="newTopic()" :disabled="disabledConfirmTopic">Confirmar</el-button>
              </span>
          </el-dialog>
  
          <!-- DIALOG TOPIC - EDIT -->
          <el-dialog
              title="Editar Tópico"
              :visible.sync="openTopicEdit"
              width="30%"
              center>
              <div style="text-align:center !important">
                  <el-form style="text-align:center">
                      <el-input v-model="topicNameEdit" style="width:50%" placeholder="Nome do tópico"></el-input>
                  </el-form>
              </div>
              <span slot="footer" class="dialog-footer">
                  <el-button @click="openTopicEdit = false">Cancelar</el-button>
                  <el-button type="primary" @click="updateTopic()" :disabled="disabledConfirmTopic">Confirmar</el-button>
              </span>
          </el-dialog>
  
          <!-- NOVA MENSAGEM -->
          <el-dialog
              title="Nova Notificação"
              @open="sendMixPanelEvent('new_push_notification_form_opened')"
              @close="sendMixPanelEvent('new_push_notification_form_closed')"
              :visible.sync="dialogVisible"
              :close-on-press-escape="false"
              :close-on-click-modal="false">
              <div class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">            
                  <el-input 
                      id="input_title"
                      placeholder="Título" 
                      v-model="title"
                      maxlength="100">
                  </el-input>
              </div>
              <div class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                  <el-input 
                      id="input_message"
                      type="textarea"
                      :rows="3"
                      placeholder="Mensagem"
                      maxlength="300"
                      v-model="message">
                  </el-input>
              </div>
              <div class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">            
                  <el-input
                      style="margin-top: 14px;"
                      id="input_link"
                      placeholder="Link" 
                      v-model="url">
                  </el-input>
              </div>
              <div style="margin: 15px 0;"></div>
              <div v-if="tableTopics.length > 0" class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                  <div class="box-topics">
                      <div style="margin: 15px 0;"></div>
                          <el-radio v-for="item in tableTopics" :label="item.name" :key="item.id" v-model="selectTopic">{{item.name}}</el-radio>
                  </div>
              </div>
              <div v-if="this.errorsForm.length" class="md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                  <p>
                      <b>Por favor, corrija o(s) seguinte(s) erro(s):</b>
                      <ul>
                      <li v-for="error in this.errorsForm" :key="error">{{ error }}</li>
                      </ul>
                  </p>
              </div>
              <span slot="footer" class="dialog-footer">
                  <div class="md-layout">
                      <div class="md-layout-item md-size-50" style="padding-left: 0;">
                          <md-button class="md-success md-block" @click="registerPushNotification(1)">Enviar</md-button>
                      </div>
                      <div class="md-layout-item md-size-50" style="padding-right: 0;">
                          <md-button class="md-default md-block" @click="dialogVisible = false">Cancelar</md-button>
                      </div>
                  </div>
              </span>
          </el-dialog>
  
          <!-- NOVO AGENDAMENTO -->
          <el-dialog 
              title="Novo Agendamento"
              @open="sendMixPanelEvent('schedule_push_notification_form_opened')"
              @close="sendMixPanelEvent('schedule_push_notification_form_closed')"
              :visible.sync="dialogSchedulle"
              :close-on-press-escape="false"
              :close-on-click-modal="false">
                  <div class="md-layout">
                      <div class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                          <el-input 
                              class="input_schedule_title"
                              placeholder="Título" 
                              v-model="titleSchedule"
                              maxlength="50">
                          </el-input>
                      </div>
                      <div class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                          <el-input 
                              class="input_schedule_message"
                              type="textarea"
                              :rows="3"
                              placeholder="Mensagem"
                              maxlength="300"
                              v-model="messageSchedule">
                          </el-input>
                      </div>
                      <div class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">            
                          <el-input
                              style="margin-top: 14px;"
                              id="input_link"
                              placeholder="Link" 
                              v-model="urlSchedule">
                          </el-input>
                      </div>
                      <div style="margin: 10px 10px;"></div>
                      <div v-if="tableTopics.length > 0" class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                          <div class="box-topics">
                              <div style="margin: 15px 0;"></div>
                                  <el-radio v-for="item in tableTopics" :label="item.name" :key="item.id" v-model="selectTopic">{{item.name}}</el-radio>
                          </div>
                      </div>
                      <div style="margin-top: 14px;" class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-50 md-xlarge-size-50 dateTime-schedule">
                          <el-date-picker
                              v-model="dateSchedule"
                              type="date"
                              placeholder="Selecione a data"
                              format="dd/MM/yyyy"
                              :picker-options="pickerOptions">
                          </el-date-picker>
                      </div>
                      <div style="margin-top: 14px;" class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-50 md-xlarge-size-50 dateTime-schedule">
                          <el-time-select
                              v-model="timeSchedule"
                              :picker-options="timerOptions"
                              placeholder="Selecione a hora">
                          </el-time-select> <br>
                      </div>
                      <div v-if="this.errorsScheduleForm.length" class="md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                          <p>
                              <b>Por favor, corrija o(s) seguinte(s) erro(s):</b>
                              <ul>
                              <li v-for="error in this.errorsScheduleForm" :key="error">{{ error }}</li>
                              </ul>
                          </p>
                      </div>
                      <div ref="msgErroHorario1" class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100 msgErroHorario1">
                          A data e horário selecionados não são permitidos!
                      </div>
                      <div ref="msgErroHorario2" class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100 msgErroHorario2">
                          Já existe um agendamento pra data e horário selecionados!
                      </div>
                  </div>
              <span slot="footer" class="dialog-footer">
                  <div class="md-layout">
                      <div class="md-layout-item md-size-50" style="padding-left: 0;">
                          <md-button class="md-success md-block" @click="registerPushNotificationSchedule(4)">Confirmar</md-button>
                      </div>
                      <div class="md-layout-item md-size-50" style="padding-right: 0;">
                          <md-button class="md-default md-block" @click="dialogSchedulle = false">Cancelar</md-button>
                      </div>
                  </div>
              </span>
          </el-dialog>
  
          <!-- EDITAR AGENDAMENTO -->
          <el-dialog
              title="Editar Agendamento"
              :visible.sync="editdialogSchedulle"
              :close-on-press-escape="false"
              :close-on-click-modal="false">
              <div class="md-layout">
                      <div class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                          <el-input
                              class="input_schedule_title"
                              placeholder="Título" 
                              v-model="titleSchedule"
                              maxlength="50">
                          </el-input>
                      </div>
                      <div class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                          <el-input 
                              class="input_schedule_message"
                              type="textarea"
                              :rows="3"
                              placeholder="Mensagem"
                              maxlength="300"
                              v-model="messageSchedule">
                          </el-input>
                      </div>
                      <div style="margin-top: 14px;" class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">            
                          <el-input
                              id="input_link"
                              placeholder="Link" 
                              v-model="urlSchedule">
                          </el-input>
                      </div>
                      <div style="margin-top: 14px;" class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-50 md-xlarge-size-50 dateTime-schedule">
                          <el-date-picker
                              v-model="dateSchedule"
                              type="date"
                              placeholder="Selecione a data"
                              format="dd/MM/yyyy"
                              :picker-options="pickerOptions">
                          </el-date-picker>
                      </div>
                      <div style="margin-top: 14px;" class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-50 md-xlarge-size-50 dateTime-schedule">
                          <el-time-select
                              v-model="timeSchedule"
                              :picker-options="timerOptions"
                              placeholder="Selecione a hora">
                          </el-time-select>
                      </div>
                      <div ref="msgErroHorario1" class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100 msgErroHorario1">
                          A data e horário selecionados não são permitidos!
                      </div>
                      <div ref="msgErroHorario2" class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100 msgErroHorario2">
                          Já existe um agendamento pra data e horário selecionados!
                      </div>
                  </div>
                  <div v-if="this.errorsScheduleForm.length" class="md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                      <p>
                          <b>Por favor, corrija o(s) seguinte(s) erro(s):</b>
                          <ul>
                          <li v-for="error in this.errorsScheduleForm" :key="error">{{ error }}</li>
                          </ul>
                      </p>
                  </div>
              <span slot="footer" class="dialog-footer">
                  <div class="md-layout">
                      <div class="md-layout-item md-size-50" style="padding-left: 0;">
                          <md-button class="md-default md-block" @click="editdialogSchedulle = false">Cancelar</md-button>
                      </div>
                      <div class="md-layout-item md-size-50" style="padding-right: 0;">
                          <md-button class="md-success md-block" @click="updateMessageSchedulle()">Atualizar</md-button>
                      </div>
                  </div>
              </span>
          </el-dialog>
      </div>
      <block v-show="blockStatus == 0"></block>    
    </div>
  </template>
  
  <script>
      import Vue from 'vue'
      import Swal from 'sweetalert2'
      import models from '../../routes/models.js'   
      import services from '../../routes/services.js'
      import selectRadio from '../../utils/events/select-radio.js'
      import block from './Block.vue'
      import jwt_decode from "jwt-decode"
      import mixpanelAdapter from '../../utils/events/mixpanelAdapter'

      const numeral = require('numeral')
      numeral.locale('pt-br')
      Vue.filter("formatNumber", function (value) {
          return numeral(value).format("0,0"); // displaying other groupings/separators is possible, look at the docs
      })
      import {
          Dialog,
          Button,
          Input,
          Tabs, 
          TabPane,
          DatePicker,
          TimePicker,
          TimeSelect,
          Select,
          Option,
          Checkbox,
          CheckboxGroup,
          Form,
          Radio
      } from 'element-ui'
      import {Pagination} from '@/components'
      import Fuse from 'fuse.js'
  
      async function RegisterPush(type_id, app_key, title, message, url, topic, date, sender) {
          let dataForm = {
              pushNotificationTypeId: type_id,
              appKey: app_key,
              title: title,
              message: message,
              url: url ? url : '',
              topic: topic ? topic : "",
              date: date,
              sender: sender,
              status: 'Enviando'
          }
  
          return await models().post('notification/Register', dataForm)
      }
  
      export default {
          components: {
              [Button.name]: Button,
              [Dialog.name]: Dialog,
              [Input.name]: Input,
              [Tabs.name]: Tabs,
              [TabPane.name]: TabPane,
              [DatePicker.name]: DatePicker,
              [TimePicker.name]: TimePicker,
              [TimeSelect.name]: TimeSelect,
              [Select.name]: Select,
              [Option.name]: Option,
              [Form.name]: Form,
              [Checkbox.name]: Checkbox,
              [CheckboxGroup.name]: CheckboxGroup,
              [Radio.name]: Radio,
              Pagination,
              block
          },
          computed: {
              queriedData () {
                  let result = this.tableData;
                  if(this.searchedData.length > 0){
                      result = this.searchedData;
                  }
                  return result.slice(this.from, this.to)
              },
              to() {
                  let highBound = this.from + this.pagination.perPage
                  if (this.total < highBound) {
                      highBound = this.total
                  }
                  return highBound
              },
              from () {
                  return this.pagination.perPage * (this.pagination.currentPage - 1)
              },
              total () {
                  return this.searchedData.length > 0 ? this.searchedData.length : this.tableData.length;
              },
              validateForm () {
                  return [this.title, this.message].join()
              },
              // Schedule
              queriedDataSchedule () {
                  let result = this.tableSchedule;
                  if(this.searchedDataSchedule.length > 0){
                      result = this.searchedDataSchedule;
                  }
                  return result.slice(this.from, this.to)
              },
              toSchedule() {
                  let highBound = this.from + this.paginationSchedule.perPage
                  if (this.total < highBound) {
                      highBound = this.total
                  }
                  return highBound
              },
              fromSchedule() {
                  return this.paginationSchedule.perPage * (this.paginationSchedule.currentPage - 1)
              },
              totalSchedule() {
                  return this.searchedDataSchedule.length > 0 ? this.searchedDataSchedule.length : this.tableSchedule.length;
              },
              validateFormShedule () {
                  return [this.titleSchedule, this.messageSchedule, this.timeSchedule, this.dateSchedule].join()
              }
          },
          data() {
              return {
                  appKey: jwt_decode(localStorage.getItem('APP_01')).radio.key,
                  serverKey: jwt_decode(localStorage.getItem('APP_01')).radio.server_key,
                  radio: jwt_decode(localStorage.getItem('APP_01')).radio,
                  user: jwt_decode(localStorage.getItem('APP_01')).radio.level,
                  dialogVisible: false,
                  ids: null,
                  tableData: [],
                  lastTimeUpdated: new Date(),
                  tabPosition: 'top',
                  title: '',
                  message: '',
                  searchTitle: '',
                  searchTitleSchedule: '',
                  disabledSchedule: true,
                  loading: false,
                  currentSort: 'title',
                  currentSortOrder: 'asc',
                  searchedData: '',
                  pagination: {
                      perPage: 10,
                      currentPage: 1,
                      perPageOptions: [5, 10, 25, 50],
                      total: 0
                  },
                  paginationSchedule: {
                      perPage: 10,
                      currentPage: 1,
                      perPageOptions: [5, 10, 25, 50],
                      total: 0
                  },
                  propsToSearch: ['title', 'message'],
                  blockStatus: 1,
                  androidNum: 0,
                  appleNum: 0,
                  windowsNum: 0,
                  valideForm: true,
                  disabled: true,
                  activeName: 'first',
                  dialogSchedulle: false,
                  titleSchedule: '',
                  messageSchedule: '',
                  urlSchedule: '',
                  timeSchedule: null,
                  dateSchedule: null,
                  pickerOptions: {
                      disabledDate(time) {
                          let newdate = new Date()
                          return time < newdate.setDate(newdate.getDate()-1)
                      }
                  },
                  timerOptions: {
                      start: '00:00',
                      step: '00:15',
                      end: '23:59'
                  },
                  tableSchedule: [],
                  searchedDataSchedule: '',
                  editdialogSchedulle: false,
                  updateId: '',
                  validFormShedule: true,
                  errorsForm: [],
                  errorsScheduleForm: [],
                  openTopic: false,
                  topicName: '',
                  tableTopics: [],
                  topicNameEdit: '',
                  editTopicId: '',
                  openTopicEdit: false,
                  selectTopic: 'Geral',
                  totalSubscribersByTopic: [],
                  activeSubscribersByTopic: [],
                  disabledConfirmTopic: false,
                  url: '',
                  sentTokens: []
              }
          },
          methods: {
              getPushNotifications() {
                  let paramSearch = null
                  this.tableData = []
                  this.loading = true
                  if (!this.searchTitle) {
                      paramSearch = '%%'
                  } else {
                      paramSearch = this.searchTitle
                  }            
                  models().get(`notification/getPushNotifications?appKey=${this.appKey}&search=${paramSearch}`)
                  .then((resp) => {
                      this.lastTimeUpdated = new Date()

                      resp.data.forEach(element => {
                          this.tableData.push({
                              id: element.id,
                              app_key: element.app_key,
                              title: element.title,
                              message: element.message,
                              url: (element.url == "null") ? "" : element.url ? element.url : "",
                              count: element.count,
                              date: element.date,
                              push_notification_type_id: element.push_notification_type_id,
                              sender: element.sender,
                              sent_count: element.sent_count,
                              status: element.status,
                              topic: element.topic ? element.topic : "Geral"
                          })
                      });
                      this.getActiveSubscribersByTopic()
                      // this.getTotalSubscribersByTopic()
                  })
                  .catch((e) => {
                      console.log(e)
                  })
              },
  
              getPushNotificationsSchedule() {
                  let paramSearch = null
                  this.tableSchedule = []
                  if (!this.searchTitleSchedule) {
                      paramSearch = "%%"
                  } else {
                      paramSearch = this.searchTitleSchedule
                  }            
                  models().get(`notification/getPushNotificationsSchedule?appKey=${this.appKey}&search=${paramSearch}`)
                  .then((resp) => {
                      this.tableSchedule = resp.data
                  })
                  .catch((e) => {
                      console.log(e)
                  })
              },
  
              isNumber (text) {
                  if(text) {
                      var reg = new RegExp('[0-9]+$');
                      return reg.test(text);
                  }
                  return false;
              },
  
              removeSpecials (text) {
                  if(text) {
                      var lower = text.toLowerCase();
                      var upper = text.toUpperCase();
                      var result = "";
                      for(var i=0; i<lower.length; ++i) {
                          if(this.isNumber(text[i]) || (lower[i] != upper[i]) || (lower[i].trim() === '')) {
                              result += text[i];
                          }
                      }
                      return result;
                  }
                  return '';
              },
  
              registerPushNotification(typeId){
                  this.errorsForm = []
                  this.validationErrors()
                  if(!this.errorsForm.length) {
                      const sender = jwt_decode(localStorage.getItem('SET_APP')).user[0].email
                      const status = "Enviando"
                      let date = new Date()
                      let time = new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds()
                      date = date.getFullYear() + '-' + (date.getMonth()+1) + '-' + date.getDate() + ' ' + time
                      this.dialogVisible = false
                      Swal.fire({
                          title: "Preparando alertas...",
                          onOpen: async () => {
                              Swal.showLoading()
                              var selectedTopicSubscribers = (this.activeSubscribersByTopic.filter(obj => obj.topic === this.selectTopic))[0]
                              if(selectedTopicSubscribers && selectedTopicSubscribers.ios_count+selectedTopicSubscribers.android_count!==0){
                                  const resp = await RegisterPush(typeId, this.appKey, this.title, this.message, this.url, this.selectTopic ? this.selectTopic : "Geral", date, sender)
                                  
                                  const id = resp.data[0].lastInsertedId
                                  const serverKey = resp.data[0].service_account ? resp.data[0].service_account: this.serverKey;

                                  this.sendMessages(serverKey, this.appKey, this.title, this.message, this.url, this.selectTopic, id)
                                  this.sendMixPanelEvent(`push_notifications_sent`)
                              }else{
                                  Swal({
                                      type: 'error',
                                      title: 'Sem inscritos',
                                      text: `Não há nenhum dispositivo inscrito no tópico '${this.selectTopic}'.`
                                  })
                              }
                             
                              
                          }
                      })
                  }
              },
  
              registerPushNotificationSchedule(typeId){
                      this.errorsScheduleForm = []
                      this.validationErrorsSchedule()
                      if(!this.errorsScheduleForm.length) {
                              const sender = jwt_decode(localStorage.getItem('SET_APP')).user[0].email
                              let date = new Date()
                              let time = new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds()
                              date = date.getFullYear() + '-' + (date.getMonth()+1) + '-' + date.getDate() + ' ' + time
                              let dateSchedule = new Date(this.dateSchedule)
                              dateSchedule = dateSchedule.getFullYear() + '-' + (dateSchedule.getMonth()+1) + '-' + dateSchedule.getDate() + ' ' + this.timeSchedule
  
                              let dataForm = {
                                  pushNotificationTypeId: typeId,
                                  appKey: this.appKey,
                                  title: this.titleSchedule,
                                  message: this.messageSchedule,
                                  url: this.urlSchedule,
                                  topic: this.selectTopic,
                                  schedule_time: dateSchedule,
                                  date: date,
                                  sender: sender,
                                  status: ""
                              }
                              Swal.fire({
                                  title: "Agendando alerta...",
                                  onOpen: () => {
                                      Swal.showLoading()
                                      var selectedTopicSubscribers = (this.activeSubscribersByTopic.filter(obj => obj.topic === this.selectTopic))[0]
                                      if(selectedTopicSubscribers && selectedTopicSubscribers.ios_count+selectedTopicSubscribers.android_count!==0){
  
                                          models().post('notification/Register', dataForm)
                                          .then((resp) => {
                                              this.dialogSchedulle = false
                                              Swal(
                                                  'Pronto',
                                                  'Mensagem agendada com sucesso!',
                                                  'success'
                                              )
                                              this.getPushNotificationsSchedule()
                                              this.sendMixPanelEvent(`push_notifications_scheduled`)
                                          })
                                          .catch((e) => {
                                              this.dialogSchedulle = false
                                              Swal(
                                                  'Falha',
                                                  'Erro ao agendar mensagem. Por favor, tente novamente.',
                                                  'error'
                                              )
                                              console.log(e)
                                          })
                                      }else{
                                          Swal({
                                              type: 'error',
                                              title: 'Sem inscritos',
                                              text: `Não há nenhum dispositivo inscrito no tópico '${this.selectTopic}'.`
                                          })
                                      }
                                  }
                              })
                          
                      }
              },
  
              async sendMessages(serverKey, appKey, title, message, url, topic, id) {
                  await
                  services().post('services/pushNotification', {
                      serverKey: serverKey,
                      appKey: appKey,
                      title: title,
                      message: message,
                      content_url: url ? url : "",
                      topic: topic ? topic : "Geral",
                      id: id
                  })
                  .then((resp) => {
                      Swal(
                          'Pronto',
                          'As mensagens começarão a ser enviadas para os dispositivos e enquanto isso você pode continuar navegando pelo Painel.',
                          'success'
                      ).then(()=>{
                          this.getPushNotifications()
                      })
                  })
                  .catch((e) => {
                      Swal(
                          'Erro',
                          'Não foi possível enviar notificações nesse . Tente novamente ou entre em contato com nosso suporte.',
                          'error'
                      )
                      console.log(e)
                  })
              },
  
              async checkScheduling() {
                  let dateNow = new Date()
                  let timeNow = new Date().toLocaleTimeString()
                  let dateSchedule = new Date(this.dateSchedule)
                  dateSchedule = dateSchedule.getFullYear() + '-' + (dateSchedule.getMonth()+1) + '-' + dateSchedule.getDate() + ' ' + this.timeSchedule +':00'
                  dateNow = dateNow.getFullYear() + '-' + (dateNow.getMonth()+1) + '-' + dateNow.getDate() + ' ' + timeNow
                  if(dateSchedule < dateNow) {
                      // this.$refs.msgErroHorario1.style.display = "block"
                      return false
                  } else {
                      return await models().get(`notification/checkScheduling?appKey=${this.appKey}&dateTime=${dateSchedule}`)
                      .then((resp) => {
                          return resp.data.length
                          // if(resp.data.length > 0) {
                          //     this.$refs.msgErroHorario2.style.display = "block"
                          // } else {
                              // this.registerPushNotificationSchedule(4)
                          // }
                      })
                      .catch((e) => {
                          console.log(e)
                      })
                  }
              },
              
              customSort (value) {
                  return value.sort((a, b) => {
                      const sortBy = this.currentSort
                      if (this.currentSortOrder === 'desc') {
                          return a[sortBy].localeCompare(b[sortBy])
                      }
                      return b[sortBy].localeCompare(a[sortBy])
                  })
              },
  
              blockPage() {
                  const menu = jwt_decode(localStorage.getItem('APP_02')).services
                  let service = null
                  for(let obj of menu) {
                      if(obj.subitens === null) {
                          const key = Object.keys(obj.item)[Object.values(obj.item).indexOf(this.$route.path.replace("/", ""))]
                          if(key) {
                              service = obj.item
                          }
                      } else {
                          const key = Object.keys(obj.subitens)[Object.values(obj.subitens).indexOf(this.$route.path.replace("/", ""))]
                          if(key) {
                              service = obj.subitens
                          }
                      }
                  }
                  
                  if(service) {
                      this.blockStatus = service.has_permission
                  } else {
                      this.$router.push("dashboard")
                  }
              },
  
              findObjectByKey(array, key, value) {
                  for (var i = 0; i < array.length; i++) {
                      if (array[i][key] === value) {
                          return array[i];
                      }
                  }
                  return null;
              },
  
              onEnterClick() {
                  // if(this.disabled == false) {
                      this.getPushNotifications()
                  // }
              },
  
              onEnterClickSchedule() {
                  // if(this.disabledSchedule == false) {
                      this.getPushNotificationsSchedule()
                  // }
              },
  
              newDialogSchedulle() {
                  this.titleSchedule = ''
                  this.messageSchedule = ''
                  this.urlSchedule = ''
                  this.dateSchedule = null
                  this.timeSchedule = null
                  this.dialogSchedulle = true
              },
  
              editDialogSchedulle(item) {
                  this.updateId = item.id
                  this.titleSchedule = decodeURIComponent(item.title)
                  this.messageSchedule = decodeURIComponent(item.message)
                  this.urlSchedule = item.url
                  this.dateSchedule = new Date(item.schedule_time)
                  this.timeSchedule = new Date(item.schedule_time).toLocaleTimeString('pt-br').substring(0,5)
                  this.editdialogSchedulle = true
              },
  
              updateMessageSchedulle() {
                      this.errorsScheduleForm = []
                      this.validationErrorsSchedule()
                      if(!this.errorsScheduleForm.length) {
                          const sender = jwt_decode(localStorage.getItem('SET_APP')).user[0].email
                          let date = new Date()
                          let time = new Date().getHours() + ':' + new Date().getMinutes()
                          date = date.getFullYear() + '-' + (date.getMonth()+1) + '-' + date.getDate() + ' ' + time
                          let dateSchedule = new Date(this.dateSchedule)
                          dateSchedule = dateSchedule.getFullYear() + '-' + (dateSchedule.getMonth()+1) + '-' + dateSchedule.getDate() + ' ' + this.timeSchedule
                          let dataForm = {
                              id: this.updateId,
                              appKey: this.appKey,
                              title: this.titleSchedule,
                              message: this.messageSchedule,
                              schedule_time: dateSchedule,
                              date: date,
                              sender: sender,
                              url: this.urlSchedule,
                              status: ""
                          }
                          if(new Date(dateSchedule) < new Date(date)) {
                              this.$refs.msgErroHorario1.style.display = "block"
                          } else {
                              this.$refs.msgErroHorario1.style.display = "none"
                              models().get(`notification/checkScheduling?dateTime=${dateSchedule}`)
                              .then((resp) => {
                                      this.editdialogSchedulle = false
                                      Swal.fire({
                                          title: "Atualizando Mensagem...",
                                          onOpen: () => {
                                              Swal.showLoading()
                                                  models().put('notification/UpdateMessageSchedule', dataForm)
                                                  .then((resp) => {
                                                      Swal(
                                                          'Pronto',
                                                          'Mensagem atualizada com sucesso!',
                                                          'success'
                                                      )
                                                      this.getPushNotificationsSchedule()
                                                  })
                                                  .catch((e) => {
                                                      Swal(
                                                          'Falha',
                                                          'Erro ao atualizar mensagem. Por favor, tente novamente.',
                                                          'error'
                                                      )
                                                      console.log(e)
                                                  })
                                          }
                                      })
                                  // }
                              })
                              .catch((e) => {
                                  console.log(e)
                              })
                          }
                          
                      }
              },
  
              deletePushSchedule(message) {
                  let index = this.tableSchedule.findIndex((item) => item.id === message.id);
                  if(index !== -1) {
                      Swal.fire({
                          title: 'Atenção',
                          text: "Deseja realmente apagar esse agendamento?",
                          type: 'warning',
                          showCancelButton: true,
                          confirmButtonColor: '#3085d6',
                          cancelButtonColor: '#d33',
                          confirmButtonText: 'Confirmar',
                          cancelButtonText: 'Cancelar'
                      }).then((result) => {
                          if (result.value) {
                              models().post("notification/deletePushSchedule", {id: message.id})
                              .then((resp) => {
                                  // this.getPushNotificationsSchedule()
                                  this.tableSchedule.splice(index, 1);
                              })
                              .catch((e) => {
                                  console.log(e)
                              })
                          }
                      })
                  }
              },
  
              validationErrors() {
                  if(this.title === '') {
                      this.errorsForm.push('O campo "Título" é obrigatório.');
                      document.getElementById("input_title").style.borderColor = "#ff0000";
                  } else {
                      document.getElementById("input_title").style.borderColor = "";
                  }
                  if(this.message === '') {
                      this.errorsForm.push('O campo "Mensagem" é obrigatório.');
                      document.getElementById("input_message").style.borderColor = "#ff0000";
                  }
                  else if(this.message.length < 3) {
                      this.errorsForm.push('O campo "Mensagem" é de no mínimo 3 caracteres.');
                      document.getElementById("input_message").style.borderColor = "#ff0000";
                  }
                  else {
                      document.getElementById("input_message").style.borderColor = "";                    
                  }
              },
  
              validationErrorsSchedule() {
                  if(this.titleSchedule === '') {
                      this.errorsScheduleForm.push('O campo "Título" é obrigatório.');
                      document.getElementsByClassName("input_schedule_title")[0].childNodes[1].style.borderColor = "#ff0000";
                  } else {
                      document.getElementsByClassName("input_schedule_title")[0].childNodes[1].style.borderColor = "";
                  }
                  if(this.messageSchedule === '') {
                      this.errorsScheduleForm.push('O campo "Mensagem" é obrigatório.');
                      document.getElementsByClassName("input_schedule_message")[0].childNodes[0].style.borderColor = "#ff0000";
                  }
                  else if(this.messageSchedule.length < 3) {
                      this.errorsScheduleForm.push('O campo "Mensagem" é de no mínimo 3 caracteres.');
                      document.getElementsByClassName("input_schedule_message")[0].childNodes[0].style.borderColor = "#ff0000";
                  }
                  else {
                      document.getElementsByClassName("input_schedule_message")[0].childNodes[0].style.borderColor = "";                    
                  }
                  if(this.dateSchedule === null || this.dateSchedule === '') {
                      this.errorsScheduleForm.push('É necessário selecionar a data do agendamento.');
                      document.getElementsByClassName("el-date-editor")[0].childNodes[1].style.borderColor = "#ff0000";
                  } else {
                      document.getElementsByClassName("el-date-editor")[0].childNodes[1].style.borderColor = "";
                  }
                  if(this.timeSchedule === null || this.timeSchedule === '') {
                      this.errorsScheduleForm.push('É necessário selecionar a hora do agendamento.');
                      document.getElementsByClassName("el-date-editor--time-select")[0].childNodes[1].style.borderColor = "#ff0000";
                  } else {
                      document.getElementsByClassName("el-date-editor--time-select")[0].childNodes[1].style.borderColor = "";
                  }
              },
  
              newTopic() {
                  let form = {
                      appKey: this.appKey,
                      name: this.topicName
                  }
                  models().post("notification/newTopic", form)
                  .then((resp) => {
                      if(resp.status === 200 || resp.status === 204) {
                          this.getTopic()
                          this.dialogVisible = false
                          this.openTopic = false
                      }
                      this.sendMixPanelEvent('push_notification_topic_created', {
                        topic: form.name
                      })
                  })
                  .catch((err) => {
                      console.log(err)
                      Swal(
                          'Erro',
                          'Não foi possível criar um novo tópico, por favor, tente novamente.',
                          'error'
                      )
                  })
              },
  
              getTopic() {
                  this.tableTopics = []
                  models().get(`notification/getTopic?appKey=${this.appKey}`)
                  .then((resp) => {
                      if(resp.data) {
                          this.tableTopics = resp.data
                          this.loading = false
                      }
                  })
                  .catch((err) => {
                      console.log(e)
                      Swal(
                          'Erro',
                          "Não foi possível buscar os tópicos, por favor, tente novamente.",
                          'error'
                      )
                  })
              },
  
              editTopic(id, name) {
                  this.openTopicEdit = true
                  this.editTopicId = id
                  this.topicNameEdit = name
              },
  
              updateTopic() {
                  let form = {
                      id: this.editTopicId,
                      name: this.topicNameEdit
                  }
                  
                  if(this.topicNameEdit) {
                      models().put("notification/updateTopic", form)
                      .then((resp) => {
                          if(resp.status === 200 || resp.status === 204) {
                              this.editTopicId = ''
                              this.topicNameEdit = ''
                              this.getTopic()
                              this.openTopicEdit = false
                          }
                      })
                      .catch((err) => {
                          console.log(err)
                          Swal(
                              'Erro',
                              'Não foi possível atualizar o tópico, por favor, tente novamente.',
                              'error'
                          )
                      })
                  }
              },
  
              deleteTopic(id) {
                const topicName = this.tableTopics.filter(item=> item.id === id)[0].name
                  Swal({
                      title: 'Apagar tópico?',
                      text: "Clique em confirmar para excluir o tópico.",
                      type: 'warning',
                      showCancelButton: true,
                      confirmButtonColor: '#3085d6',
                      cancelButtonColor: '#d33',
                      cancelButtonText: 'Cancelar',
                      confirmButtonText: 'Confirmar'
                  }).then((result) => {
                      if (result.value) {
                          models().delete(`notification/deleteTopic?id=${id}`)
                          .then((resp) => {               
                              this.sendMixPanelEvent('push_notification_topic_deleted', {
                                topic: topicName
                              })
                              this.getPushNotifications()
                              this.getPushNotificationsSchedule()
                          })
                          .catch((err) => {
                              console.log(err)
                          })
                      }
                  })
              },
  
              getActiveSubscribersByTopic() {
                  models().get(`notification/getActiveSubscribersByTopic?key=${this.appKey}`)
                      .then((response) => {
                          this.activeSubscribersByTopic = response.data
                          this.totalSubscribersByTopic = response.data
                          this.getTopic()
                          this.loading = false
                      })
                      .catch((error) => {
                          console.log(error)
                      })
              },
              
              getTotalSubscribersByTopic() {
                  models().get(`notification/getTotalSubscribersByTopic?key=${this.appKey}`)
                      .then((response) => {
                          this.totalSubscribersByTopic = response.data
                          this.getTopic()
                          this.loading = false
                      })
                      .catch((error) => {
                          console.log(error)
                      })
              },
  
              helpCard() {
                  Swal({
                      title: "Inscritos x Ativos",
                      html:
                          "<ul><li style='text-align:left'>Dispositivos ativos: São os dispositivos que interagiram com o aplicativo nos últimos 6 meses e portanto receberão as notificações.</li><li style='text-align:left'>Dispositivos inscritos: São os dispostivos inscritos desde o primeiro dia em que o aplicativo ficou disponível mas só os que estão em atividade receberão notificações.</li></ul>"
                  });
              },
              sendMixPanelEvent(eventString, params){
                mixpanelAdapter.track(eventString, {
                    ...params,
                    screen_name: this.$route.name
                })
              }
              
          },
          mounted() {
              if(jwt_decode(localStorage.getItem('APP_01')).radio) {
                  this.appKey = jwt_decode(localStorage.getItem('APP_01')).radio.key
                  this.serverKey = jwt_decode(localStorage.getItem('APP_01')).radio.server_key
                  this.radio = jwt_decode(localStorage.getItem('APP_01')).radio
                  this.getPushNotifications()
                  this.getPushNotificationsSchedule()
  
                  // this.blockPage()
              }
              selectRadio.$on('selectRadio', (payload) => {
                  this.appKey = payload.key
                  this.serverKey = payload.server_key
                  this.radio.master_key = payload.master_key
                  this.getPushNotifications()
                  this.getPushNotificationsSchedule()
                  // this.blockPage()
              })
              this.fuseSearch = new Fuse(this.tableData, {keys: ['title', 'message'], threshold: 0.3})
          },
  
          watch: {
              searchTitle(param) {
                  if(param.length >= 3) {
                      this.disabled = false
                  } else {
                      this.disabled = true
                  }
              },
              searchTitleSchedule(param) {
                  if(param.length >= 3) {
                      this.disabledSchedule = false
                  } else {
                      this.disabledSchedule = true
                  }
              },
              validateForm() {
                  if(this.title != '' && this.message != '') {
                      this.valideForm = false
                  } else {
                      this.valideForm = true
                  }
              },
              validateFormShedule() {
                  if(this.titleSchedule !== '' && this.messageSchedule !== '' && this.timeSchedule !== null && this.dateSchedule !== null) {
                      this.validFormShedule = false
                  } else {
                      this.validFormShedule = true
                  }
              },
              dateSchedule() {
                  this.$refs.msgErroHorario1.style.display = "none"
                  this.$refs.msgErroHorario2.style.display = "none"
              },
              timeSchedule() {
                  this.$refs.msgErroHorario1.style.display = "none"
                  this.$refs.msgErroHorario2.style.display = "none"
              },
              topicName(value) {
                  const string = value.toLowerCase().replace(/\//g, "").replace(/\\/g, "")
                  this.topicName = value.replace(/\//g, "").replace(/\\/g, "")
                  switch (string) {
                      case "iphone":
                          this.disabledConfirmTopic = true
                          break;
                      case "android":
                          this.disabledConfirmTopic = true
                          break;
                      case "ipad":
                          this.disabledConfirmTopic = true
                          break;
                      case "ipod":
                          this.disabledConfirmTopic = true
                          break;
                      default:
                          this.disabledConfirmTopic = false
                          break;
                  }
              },
              topicNameEdit(value) {
                  const string = value.toLowerCase().replace(/\//g, "").replace(/\\/g, "")
                  this.topicNameEdit = value.replace(/\//g, "").replace(/\\/g, "")
                  switch (string) {
                      case "iphone":
                          this.disabledConfirmTopic = true
                          break;
                      case "android":
                          this.disabledConfirmTopic = true
                          break;
                      case "ipad":
                          this.disabledConfirmTopic = true
                          break;
                      case "ipod":
                          this.disabledConfirmTopic = true
                          break;
                      default:
                          this.disabledConfirmTopic = false
                          break;
                  }
              }
          }
      }
  </script>
  <style>
      .row-btn-edit-push {
          width: 10% !important;
      }
      .messageTable{
          width: 15% !important;
      }
      .tdLink {
          width: 18% !important;
      }
      .divLink {
          display: inline-block;
          width: 220px;
          white-space: nowrap;
          overflow: hidden !important;
          text-overflow: ellipsis;
      }
      .contentTablePush {
          padding: 0px 45px 15px 45px !important;
      }
      .menu-1 {
          text-align: center;
          align-items: baseline;
      }
      .icon-custom{
          font-size: 30px;
      }
      .android-num {
          color: #6AB344
      }
      .apple-num {
          color: #000000;
      }
      .win-num {
          color: #3784dc;
      }
      .dateTime-schedule {
          display: flex;
          justify-content: space-between;
      }
      .dateTime-schedule>div{
          width: 100% !important;
      }
      .btn-actions {
          cursor: pointer;
      }
      .table-icon {
          width: 2% !important;
      }
      .msgErroHorario1 {
          display: none;
          color: #ff0000;
          text-align: center;
          padding-top: 20px;
      }
      .msgErroHorario2 {
          display: none;
          color: #ff0000;
          text-align: center;
          padding-top: 20px;
      }
      .el-checkbox__label {
          font-weight: 100 !important;
      }
      .box-topics {
          border: 1px solid #dcdfe6;
          border-radius: 5px;
          padding: 10px;  
      }
  </style>
