<template>
    <div>
        <div v-show="loading"
            class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
            <div class="spinner">
                <div class="rect1"></div>
                <div class="rect2"></div>
                <div class="rect3"></div>
                <div class="rect4"></div>
                <div class="rect5"></div>
            </div>
        </div>
        <div v-if="this.radio" v-show="!loading"
            class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-100">
            <md-card>
                <md-card-header class="md-card-header-icon md-card-header-blue">
                    <div class="card-icon">
                        <md-icon>info</md-icon>
                    </div>
                    <h4 class="title"> Informações</h4>
                </md-card-header>
                <md-card-content>
                    <div class="md-layout">
                        <div ref="radioKeyCopy"
                            class="md-layout-item md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50">
                            <md-field id="radio-key" class="radio-key">
                                <label>Chave da rádio (ultilizada na API)</label>
                                <md-input v-model="this.key" disabled></md-input>
                                <div class="tooltip">
                                    <button class="copy-clipboard" @click="copyToClipBoard()" @mouseout="outClipBoard()"
                                        :value="this.key">
                                        <span class="tooltiptext" id="myTooltip">Clique para copiar</span>
                                        Copiar <i class="fas fa-copy"></i>
                                    </button>
                                </div>
                            </md-field>
                        </div>
                        <div
                            class="md-layout-item md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50">
                            <md-field>
                                <label>Link de compartilhamento</label>
                                <md-input v-model="radio.share_link" disabled></md-input>
                                <div class="tooltip">
                                    <button class="copy-clipboard" @click="copyToClipBoard2()"
                                        @mouseout="outClipBoard2()" :value="radio.master_key">
                                        <span class="tooltiptext" id="myTooltip2">Clique para copiar</span>
                                        Copiar <i class="fas fa-copy"></i>
                                    </button>
                                </div>
                            </md-field>
                        </div>
                        <div
                            class="md-layout-item md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50">
                            <md-field>
                                <label>Nome</label>
                                <md-input v-model="radio.name"></md-input>
                            </md-field>
                        </div>
                        <div
                            class="md-layout-item md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50">
                            <md-field>
                                <label>Telefone</label>
                                <md-input v-model="radio.phone_number"></md-input>
                            </md-field>
                        </div>
                        <div
                            class="md-layout-item md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50">
                            <md-field>
                                <label>Email</label>
                                <md-input v-model="radio.email"></md-input>
                            </md-field>
                        </div>
                        <div
                            class="md-layout-item md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50">
                            <md-field>
                                <label>Site</label>
                                <md-input v-model="radio.site"></md-input>
                            </md-field>
                        </div>
                        <div
                            class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                        </div>
                        <div
                            class="md-layout-item md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50">
                            <div class="picture-container">
                                <span class="description">Logo</span>
                                <div class="picture">
                                    <div v-if="!this.image">
                                        <img :src="this.radio.images.logo" class="picture-src" title="">
                                    </div>
                                    <div v-else>
                                        <img :src="this.image" />
                                    </div>
                                    <input type="file" @change="onFileChangeLogo">
                                </div>
                                <span style="color:red" v-if="msgComponentImageLogo !== ''">{{msgComponentImageLogo}}</span>
                            </div>
                        </div>
                        <div
                            class="md-layout-item md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50">
                            <div class="picture-container">
                                <span class="description">Background</span>
                                <div class="picture">
                                    <div v-if="!this.Background">
                                        <img :src="this.radio.images.background" class="picture-src" title="">
                                    </div>
                                    <div v-else>
                                        <img :src="this.background" />
                                    </div>
                                    <input type="file" @change="onFileChangeBackground">
                                </div>
                                <span style="color:red" v-if="msgComponentImage !== ''">{{msgComponentImage}}</span>
                            </div>
                        </div>
                    </div>
                </md-card-content>
            </md-card>
        </div>
        <div v-if="this.radio" v-show="!loading"
            class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-100">
            <md-card>
                <md-card-header class="md-card-header-icon md-card-header-blue">
                    <div class="card-icon card">
                        <md-icon>graphic_eq</md-icon>
                    </div>
                    <h4 class="title"> Streaming</h4>
                </md-card-header>
                <md-card-content>
                    <div class="md-layout">
                        <div
                            class="md-layout-item md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50">
                            <md-field>
                                <label>URL</label>
                                <md-input v-model="radio.streaming"></md-input>
                            </md-field>
                        </div>
                        <div
                            ref="checkbox-platform-streaming"
                            v-if="this.user.level == 1 || this.user.level == 0"
                            class="md-layout-item md-size-100">
                            <div class="md-layout" style="margin: 0; padding: 0;">
                                <h5 class="md-layout-item md-size-100" style="padding: 0; margin-bottom: 0" >Plataformas bloqueadas:</h5>
                                <div class="md-layout-item md-size-100" style="padding: 0;">
                                    <div class="md-layout md-alignment-center-left">
                                        <div class="md-layout-item md-xsmall-size-100 md-small-size-25" style="padding: 0; max-width: 140px;">
                                            <md-checkbox v-model="currentStreamingPlatformsBlocked" value="android">
                                                <div class="label-streaming-checkbox">
                                                    <img
                                                        class="icon-checkbox"
                                                        src="../../../../public/img/icons/android-icon.svg">
                                                    <span>Android</span>
                                                </div>
                                            </md-checkbox>
                                        </div>
                                        <div class="md-layout-item md-xsmall-size-100 md-small-size-25" style="padding: 0; max-width: 110px;">
                                            <md-checkbox v-model="currentStreamingPlatformsBlocked" value="ios">
                                                <div class="label-streaming-checkbox">
                                                    <img 
                                                        class="icon-checkbox"
                                                        src="../../../../public/img/icons/apple-icon.svg" />
                                                    <span>iOS</span>
                                                </div>
                                            </md-checkbox>
                                        </div>
                                        <div class="md-layout-item md-xsmall-size-100 md-small-size-25" style="padding: 0; max-width: 120px;">
                                            <md-checkbox v-model="currentStreamingPlatformsBlocked" value="alexa">
                                                <div class="label-streaming-checkbox">
                                                    <img 
                                                        class="icon-checkbox"
                                                        src="../../../../public/img/icons/alexa-icon.svg" />
                                                    <span>Alexa</span>
                                                </div>
                                            </md-checkbox>
                                        </div>
                                        <div class="md-layout-item md-xsmall-size-100 md-small-size-25" style="padding: 0; max-width: 160px;">
                                            <md-checkbox v-model="currentStreamingPlatformsBlocked" value="android_tv">
                                                <div class="label-streaming-checkbox">
                                                    <img 
                                                        class="icon-checkbox"
                                                        src="../../../../public/img/icons/android-tv-icon.svg" />
                                                    <span>Android TV</span>
                                                </div>
                                            </md-checkbox>
                                        </div>
                                        <div class="md-layout-item md-xsmall-size-100 md-small-size-25" style="padding: 0; max-width: 140px;">
                                            <md-checkbox v-model="currentStreamingPlatformsBlocked" value="apple_tv">
                                                <div class="label-streaming-checkbox">
                                                    <img 
                                                        class="icon-checkbox"
                                                        src="../../../../public/img/icons/apple-tv-icon.svg" />
                                                    <span>Apple TV</span>
                                                </div>
                                            </md-checkbox>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </md-card-content>
            </md-card>
        </div>
        <div v-if="this.user.level == 0" v-show="!loading" class="md-layout">
            <div class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                <md-card>
                    <md-card-header class="md-card-header-icon md-card-header-blue">
                        <div class="card-icon card">
                            <md-icon>cloud</md-icon>
                        </div>
                        <h4 class="title"> Cloudinary</h4>
                    </md-card-header>
                    <md-card-content>
                        <div class="md-layout">
                            <div
                                class="md-layout-item md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50">
                                <md-field>
                                    <label>Cloud Name</label>
                                    <md-input v-model="radio.cloudinary_cloud_name"></md-input>
                                </md-field>
                            </div>
                            <div
                                class="md-layout-item md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50">
                                <md-field>
                                    <label>Cloud Key</label>
                                    <md-input v-model="radio.cloudinary_api_key"></md-input>
                                </md-field>
                            </div>
                            <div
                                class="md-layout-item md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50">
                                <md-field>
                                    <label>Cloud Secret</label>
                                    <md-input v-model="radio.cloudinary_api_secret"></md-input>
                                </md-field>
                            </div>
                        </div>
                    </md-card-content>
                </md-card>
            </div>
        </div>
        <div v-if="this.user.level == 0" v-show="!loading" class="md-layout">
            <div class="md-layout-item md-size-100">
                <md-card>
                    <md-card-header class="md-card-header-icon md-card-header-blue">
                        <div class="card-icon card">
                            <md-icon>phonelink_setup</md-icon>
                        </div>
                        <h4 class="title">Service account do firebase</h4>
                    </md-card-header>
                    <md-card-content>
                        <el-input
                            rows="20"
                            v-model="radio.service_account"
                            placeholder="Cole aqui o service account da rádio"
                            type="textarea"
                        ></el-input>
                    </md-card-content>
                </md-card>
            </div>
        </div>
        <div v-if="this.user.level == 0" v-show="!loading" class="md-layout">
            <md-card>
                    <md-card-header class="md-card-header-icon md-card-header-blue">
                        <div class="card-icon card">
                            <md-icon>phonelink_setup</md-icon>
                        </div>
                        <h4 class="title">Envio único de notificação push</h4>
                    </md-card-header>
                    <md-card-content>
                        <div class="md-layout">
                            <div class="md-layout-item md-size-100">
                                <md-field>
                                    <label>Server key (preencher apenas se for testar API Legada)</label>
                                    <md-input v-model="pushNotification.server_key"></md-input>
                                </md-field>
                            </div>
                            <div class="md-layout-item md-size-100">
                                <md-field>
                                    <label>Token do dispositivo</label>
                                    <md-input v-model="pushNotification.device_token"></md-input>
                                </md-field>
                            </div>
                            <div class="md-layout-item md-size-100">
                                <md-field>
                                    <label>Título da mensagem</label>
                                    <md-input v-model="pushNotification.title"></md-input>
                                </md-field>
                            </div>
                            <div class="md-layout-item md-size-100">
                                <md-field>
                                    <label>Conteúdo da mensagem</label>
                                    <md-input v-model="pushNotification.message"></md-input>
                                </md-field>
                            </div>
                            <div class="md-layout-item md-size-100">
                                <div>
                                    <md-button
                                    @click="sendPushToDevice"
                                    :disabled="!pushNotification.message || !pushNotification.title || !pushNotification.device_token"
                                    class="md-success"
                                >Enviar</md-button>
                                </div>
                            </div>
                        </div>
                    </md-card-content>
                </md-card>
        </div>
        <div v-if="this.user.level == 0" v-show="!loading" class="md-layout">
            <div class="md-layout-item md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50">
                <md-card>
                    <md-card-header class="md-card-header-icon md-card-header-blue">
                        <div class="card-icon card">
                            <md-icon>phonelink_setup</md-icon>
                        </div>
                        <h4 class="title"> AdMob Android</h4>
                    </md-card-header>
                    <md-card-content>
                        <div class="md-layout">
                            <div
                                class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                                <md-field>
                                    <label>App Id</label>
                                    <md-input v-model="radio.admob.android.app_id"></md-input>
                                </md-field>
                            </div>
                            <div
                                class="md-layout-item md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50">
                                <md-field>
                                    <label>Banner Id</label>
                                    <md-input v-model="radio.admob.android.banner"></md-input>
                                </md-field>
                            </div>
                            <div
                                class="md-layout-item md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50">
                                <md-field>
                                    <label>Interstitial Id</label>
                                    <md-input v-model="radio.admob.android.interstitial"></md-input>
                                </md-field>
                            </div>
                        </div>
                    </md-card-content>
                </md-card>
            </div>
            <div class="md-layout-item md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50">
                <md-card>
                    <md-card-header class="md-card-header-icon md-card-header-blue">
                        <div class="card-icon card">
                            <md-icon>phonelink_setup</md-icon>
                        </div>
                        <h4 class="title"> AdMob iOS</h4>
                    </md-card-header>
                    <md-card-content>
                        <div class="md-layout">
                            <div
                                class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                                <md-field>
                                    <label>App Id</label>
                                    <md-input v-model="radio.admob.ios.app_id"></md-input>
                                </md-field>
                            </div>
                            <div
                                class="md-layout-item md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50">
                                <md-field>
                                    <label>Banner Id</label>
                                    <md-input v-model="radio.admob.ios.banner"></md-input>
                                </md-field>
                            </div>
                            <div
                                class="md-layout-item md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50">
                                <md-field>
                                    <label>Interstitial Id</label>
                                    <md-input v-model="radio.admob.ios.interstitial"></md-input>
                                </md-field>
                            </div>
                        </div>
                    </md-card-content>
                </md-card>
            </div>
            <div class="md-layout-item md-size-100">
                <md-card>
                    <md-card-header class="md-card-header-icon md-card-header-blue">
                        <div class="card-icon card">
                            <md-icon>phonelink_setup</md-icon>
                        </div>
                        <h4 class="title"> Interstitial</h4>
                    </md-card-header>
                    <md-card-content>
                        <div class="md-layout">
                            <md-checkbox
                                v-model="radio.admob.admob_interstitial_blocked"
                                :true-value="1"
                                :false-value="0"
                                >
                                Desabilitar Interstitial
                            </md-checkbox>
                        </div>
                    </md-card-content>
                </md-card>
            </div>
        </div>
        <div v-if="this.user.level == 0" v-show="!loading" class="md-layout">
            <div class="md-layout-item md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50">
                <md-card>
                    <md-card-header class="md-card-header-icon md-card-header-blue">
                        <div class="card-icon card">
                            <md-icon>android</md-icon>
                        </div>
                        <h4 class="title"> URL do aplicativo na GooglePlay</h4>
                    </md-card-header>
                    <md-card-content>
                        <div class="md-layout">
                            <div
                                class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                                <md-field>
                                    <label>URL</label>
                                    <md-input v-model="radio.store_link.android"></md-input>
                                </md-field>
                            </div>
                        </div>
                    </md-card-content>
                </md-card>
            </div>
            <div class="md-layout-item md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50">
                <md-card>
                    <md-card-header class="md-card-header-icon md-card-header-blue">
                        <div class="card-icon card">
                            <i class="fab fa-apple fa-2x"></i>
                            <!-- <md-icon>apple</md-icon> -->
                        </div>
                        <h4 class="title"> URL do aplicativo na AppleStore</h4>
                    </md-card-header>
                    <md-card-content>
                        <div class="md-layout">
                            <div
                                class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                                <md-field>
                                    <label>URL</label>
                                    <md-input v-model="radio.store_link.ios"></md-input>
                                </md-field>
                            </div>
                        </div>
                    </md-card-content>
                </md-card>
            </div>
        </div>
        <div v-if="this.radio" v-show="!loading"
            class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-100">
            <md-card>
                <md-card-header class="md-card-header-icon md-card-header-blue">
                    <div class="card-icon card">
                        <md-icon>link</md-icon>
                    </div>
                    <h4 class="title"> URL com dados da música</h4>
                </md-card-header>
                <md-card-content>
                    <div class="md-layout">
                        <div
                            class="md-layout-item md-small-size-100 md-medium-size-80 md-large-size-80 md-xlarge-size-80">
                            <md-field>
                                <label>URL</label>
                                <md-input v-model="radio.xml.link" disabled></md-input>
                            </md-field>
                        </div>
                        <div
                            class="md-layout-item md-small-size-100 md-medium-size-20 md-large-size-20 md-xlarge-size-20">
                            <md-button class="md-warning md-block" @click="showPanel = true; active1 = 0"
                                > Início</md-button>
                        </div>
                        <div class="md-layout-item md-small-size-100 md-medium-size-80 md-large-size-80 md-xlarge-size-80">
                            <md-field>
                                <label>Blacklist</label>
                                <md-input disabled md-autogrow class="blacklist" v-model="arrBlackListStr"></md-input>
                            </md-field>
                        </div>
                        <div class="md-layout-item md-small-size-100 md-medium-size-20 md-large-size-20 md-xlarge-size-20">
                            <md-button class="md-info md-block" @click="editBlackList()"> Editar Blacklist</md-button>
                        </div>
                    </div>
                </md-card-content>
            </md-card>
        </div>
        <div v-if="this.radio" v-show="!loading"
            class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-100">
            <md-card>
                <md-card-header class="md-card-header-icon md-card-header-blue">
                    <div class="card-icon card">
                        <md-icon>group</md-icon>
                    </div>
                    <h4 class="title"> Redes Sociais</h4>
                </md-card-header>
                <md-card-content>
                    <div class="md-layout">
                        <div class="md-layout-item md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50"
                            v-for="item in radio.social_networks" :key="item.id">
                            <el-button style="position:initial; float:right" type="danger" icon="el-icon-delete" circle @click="deleteSocialNetwork(item.id)"></el-button>
                            <md-field>
                                <label>{{item.name}}</label>
                                <md-input v-model="item.link"></md-input>
                            </md-field>
                        </div>
                        <div class="md-layout-item md-small-size-100 md-medium-size-20 md-large-size-20 md-xlarge-size-20">
                            <md-button class="md-info md-warning" @click="openNewSocialNetwork()"> Adicionar Rede Social</md-button>
                        </div>
                    </div>
                </md-card-content>
            </md-card>
        </div>
		<NewUser v-show="user.level === 0 || user.level === 1" />
        <div v-if="this.radio" v-show="!loading"
            class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-100">
            <md-card>
                <md-card-content>
                    <div class="md-layout text-center">
                        <md-card-header class="header-card">
                            <md-button class="md-success md-block" @click="updateDataRadio()"> Atualizar informações
                            </md-button>
                        </md-card-header>
                    </div>
                </md-card-content>
            </md-card>
        </div>
        <el-dialog title="" :visible.sync="showPanel" :close-on-click-modal="false" :center="true" :lock-scroll="false">
            <!-- PASSOS -->
            <el-steps :active="active1" finish-status="success">
                <el-step title="Início"></el-step>
                <el-step title="Título da Música"></el-step>
                <el-step title="Nome do Artista"></el-step>
                <el-step title="Capa do Álbum"></el-step>
                <el-step title="Blacklist"></el-step>
                <el-step title="Validação"></el-step>
            </el-steps>
            <!--  -->

            <!-- CONFIGURAR XML -->
            <div v-show="active1 === 0" style="margin-top:20px;">
                <div class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                    <div class="md-layout divDescription">
                        <span class="info-span">
                            Prezado cliente, iniciaremos o processo de configuração da URL dos dados da música.
                            <br>Esse arquivo contém os metadados da <b>música</b> e do <B>artista</B> que está tocando na rádio.
                        </span>
                    </div>
                </div>
                <div class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100" style="margin-top:20px;">
                    <md-field>
                        <label>Insira a URL</label>
                        <md-input v-model="radio.xml.link"></md-input>
                    </md-field>
                </div>
                <!-- <div class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                      <div>
                        <small>Formato da URL</small> <br>
                        <md-radio v-model="radio.xml.type" value="0">XML</md-radio>
                        <md-radio v-model="radio.xml.type" value="1">JSON</md-radio>
                    </div>
                </div> -->
                 <div class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                    <div class="divDescription">
                        <span class="info-span">
                            Essas informações poderão ser utilizadas tanto no <b>aplicativo mobile</b> da rádio quanto também em seu <b>webplayer</b>.
                        </span>
                    </div>
                </div>

                <div class="md-layout divButton">
                    <div class="md-layout-item md-small-size-100 md-medium-size-30 md-large-size-30 md-xlarge-size-20" style="text-align: right;">
                        <el-button type="primary" style="margin-top: 12px;" :disabled="!radio.xml.link" @click="getXmlOrJsonData(radio.xml.link); next()">Iniciar</el-button>
                    </div>
                </div>
            </div>
            <!-- TITULO DA MUSICA -->
            <div v-show="active1 === 1" style="margin-top:20px;">
                <div>
                    <div class="md-layout divDescription">
                        <span class="info-span">Selecione o <b>título da música</b> que está tocando na rádio e
                            depois clique no botão na parte inferior da tela.</span>
                        <span class="info-span"><b>Atenção:</b> alguns arquivos XML contém nome da música em mais de
                            um lugar. Tenha certeza que escolheu a tag correta antes de prosseguir.</span>
                    </div>
                    <div class="md-layout divsXml" id="divsXml1" v-if="joinXMLHTML.length > 0">
                        <div class="control-div" v-for="(item, index) in joinXMLHTML" :key="index" v-html="item"
                            @click="SelectXmlMusic($event)" style="cursor:pointer;width:100%;">
                            </div>
                    </div>
                    <div class="md-layout divsXml" id="divsXml1" v-else-if="joinXMLHTMLJSON.length > 0">
                        <div class="control-div" v-for="(item, index) in joinXMLHTMLJSON" :key="index"
                            @click="SelectXmlMusic($event)" style="cursor:pointer;width:100%;">
                            <pre>{{item}}</pre>
                            </div>
                    </div>
                    <div class="md-layout divButton">
                        <div class="md-layout-item md-small-size-100 md-medium-size-30 md-large-size-30 md-xlarge-size-20" style="text-align: right;">
                            <el-button style="margin-top: 12px;" @click="previous()">Passo anterior</el-button>
                        </div>
                        <div class="md-layout-item md-small-size-100 md-medium-size-30 md-large-size-30 md-xlarge-size-20">
                            <el-button type="primary" style="margin-top: 12px;" :disabled="tagPrincipal == ''" @click="step(1); next()">Próximo passo</el-button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- NOME DO ARTISTA -->
            <div v-show="active1 === 2" style="margin-top:20px;">
                <div>
                    <div class="md-layout divDescription">
                        <span class="info-span">Selecione o <b>nome do artista</b> que está tocando na rádio e
                            depois clique no botão na parte inferior da tela.</span>
                    </div>
                    <div class="md-layout divsXml" id="divsXml2">
                        <div class="control-div" v-html="joinXMLHTML2" @click="SelectXmlArtist($event)"
                            style="cursor:pointer;width:100%;"></div>
                    </div>
                    <div class="md-layout divButton">
                        <div class="md-layout-item md-small-size-100 md-medium-size-30 md-large-size-30 md-xlarge-size-20" style="text-align: right;">
                            <el-button style="margin-top: 12px;" @click="previous()">Passo anterior</el-button>
                        </div>
                        <div class="md-layout-item md-small-size-100 md-medium-size-30 md-large-size-30 md-xlarge-size-20">
                            <el-button type="primary" style="margin-top: 12px;" :disabled="checkTag2" @click="step(2); next()">Próximo passo</el-button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- CAPA DO ALBUM -->
            <div v-show="active1 === 3" style="margin-top:20px;">
                <div>
                    <div class="md-layout divDescription">
                        <span class="info-span">Caso a URL dos dados da música da rádio possua o link com a imagem do artista, selecione a <b>capa do
                                álbum</b>.</span>
                        <span class="info-span">Caso contrário, prossiga sem selecionar nenhum item.</span>
                    </div>
                    <div class="md-layout divsXml" id="divsXml3">
                        <div class="control-div" v-html="joinXMLHTML2" @click="SelectXmlCover($event)"
                            style="cursor:pointer;width:100%;"></div>
                    </div>
                    <div class="md-layout divButton">
                        <div class="md-layout-item md-small-size-100 md-medium-size-30 md-large-size-30 md-xlarge-30 divMsg"
                            v-show="alertMsg3">
                            A tag principal "{{tagPrincipal}}" que você marcou na etapa anterior não confere.
                        </div>
                    </div>
                    <div class="md-layout divButton">
                        <div class="md-layout-item md-small-size-100 md-medium-size-30 md-large-size-30 md-xlarge-size-20" style="text-align: right;">
                            <el-button type="danger" style="margin-top: 12px;" v-show="uncheck" @click="unCheck('divsXml3')">Desmarcar</el-button>
                        </div>
                        <div class="md-layout-item md-small-size-100 md-medium-size-30 md-large-size-30 md-xlarge-size-20" style="text-align: right;">
                            <el-button style="margin-top: 12px;" @click="previous()">Passo anterior</el-button>
                        </div>
                        <div class="md-layout-item md-small-size-100 md-medium-size-30 md-large-size-30 md-xlarge-size-20">
                            <el-button type="primary" style="margin-top: 12px;" :disabled="checkTag3" @click="step(3); next()">Próximo passo</el-button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- BLACKLIST -->
            <div v-show="active1 === 4" style="margin-top:20px;">
                <div>
                    <div class="md-layout divDescription">
                        <span class="info-span">
                            Caso a URL dos dados da música contenha informações durante os <b>intervalos da programação</b> (como por exemplo: "Bloco Comercial"), esses valores deverão ser adicionados à essa lista para que eles não sejam contabilizados pela nossa plataforma como música.
                            <br/>Caso contrário, prossiga sem adicionar nenhum item.
                        </span>
                    </div>
                    <div class="md-layout divsXml">
                        <div class="md-layout-item md-xsmall-size-100 md-small-size-80 md-medium-size-65 md-large-size-90 md-xlarge-size-90 div-remove">
                            <el-input v-model="radio.name" disabled></el-input>
                            <md-button class="md-danger btn-remove" style="visibility:hidden"><i class="fas fa-minus"></i></md-button>
                        </div>
                        <div v-for="(item, index) in arrBlackList" :key="index" v-show="arrBlackList.length > 1" class="md-layout-item md-xsmall-size-100 md-small-size-80 md-medium-size-90 md-large-size-90 md-xlarge-size-90 div-remove">
                            <el-input v-model="item.name" disabled v-show="item.name !== radio.name"></el-input>
                            <md-button class="md-danger btn-remove" @click="removeBlackList(index)" v-show="item.name !== radio.name"><i class="fas fa-minus"></i></md-button>
                        </div>

                        <div class="md-layout-item md-xsmall-size-100 md-small-size-80 md-medium-size-90 md-large-size-90 md-xlarge-size-90 div-remove">
                            <el-input v-model="blackListItem" placeholder="Novo item"></el-input>
                            <md-button class="md-success btn-remove" @click="addBlackList()"><i class="fas fa-plus"></i></md-button>
                        </div>
                    </div>
                    <div class="md-layout divButton">
                        <div class="md-layout-item md-small-size-100 md-medium-size-30 md-large-size-30 md-xlarge-size-20" style="text-align: right;">
                            <el-button style="margin-top: 12px;" @click="previous()">Passo anterior</el-button>
                        </div>
                        <div class="md-layout-item md-small-size-100 md-medium-size-30 md-large-size-30 md-xlarge-size-20">
                            <el-button type="primary" style="margin-top: 12px;" :disabled="checkTag3" @click="step(4); next()">Próximo passo</el-button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- VALIDAÇÃO -->
            <div v-show="active1 === 5" style="margin-top:20px;">
                <div>
                    <div class="md-layout divDescription">
                        <span><u>Atenção:</u> antes de finalizar, verifique se todos os itens estão corretos.</span>
                    </div>
                    <div class="content-validation">
                        <div class="md-layout divDescriptionValidation">
                            <label><b>Nome da música:</b> {{strMusicName}}</label>
                        </div>
                        <div class="md-layout divDescriptionValidation">
                            <label><b>Nome do artista:</b> {{strArtistName}}</label>
                        </div>
                        <div class="md-layout divDescriptionValidation">
                            <label><b>Capa do álbum:</b> {{strCoverLink}}</label>
                        </div>
                        <div class="md-layout divDescriptionValidation">
                            <label><b>Tag Principal:</b> {{strTagPrincipal}}</label>
                        </div>
                        <div class="md-layout divDescriptionValidation">
                            <label><b>Blacklist: </b><span class="blacklist" v-for="item of arrBlackList" :key="item">{{item.name}} </span></label>
                        </div>
                    </div>

                    <div class="md-layout divButton">
                        <div class="md-layout-item md-small-size-100 md-medium-size-30 md-large-size-30 md-xlarge-size-20" style="text-align: right;">
                            <el-button style="margin-top: 12px;"  @click="previous()">Passo anterior</el-button>
                        </div>
                        <div class="md-layout-item md-small-size-100 md-medium-size-30 md-large-size-20 md-xlarge-size-10">
                            <el-button type="success" style="margin-top: 12px;"  @click="finish()">Finalizar</el-button>
                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>

        <el-dialog title="" :visible.sync="showPanelBlackList">
            <el-tabs v-model="activeBlackList">
                <el-tab-pane label="Editar Blacklist" name="tab-blackList">
                    <div>
                        <div class="md-layout divDescription">
                            <b style="color:#409EFF;">BLACKLIST</b>
                        </div>
                        <div class="md-layout divDescription">
                            <div class="md-layout-item md-xsmall-size-100 md-small-size-80 md-medium-size-65 md-large-size-90 md-xlarge-size-90 div-remove">
                                <el-input v-model="radio.name" disabled></el-input>
                                <md-button class="md-danger btn-remove" style="visibility:hidden"><i class="fas fa-minus"></i></md-button>
                            </div>
                            <div v-for="(item, index) in arrUpdateBlackList" :key="index" v-show="arrUpdateBlackList.length > 1" class="md-layout-item md-xsmall-size-100 md-small-size-80 md-medium-size-90 md-large-size-90 md-xlarge-size-90 div-remove">
                                <el-input v-model="item.name" v-show="item.name !== radio.name"></el-input>
                                <md-button class="md-danger btn-remove" @click="removeBlackListUpdate(index)" v-show="item.name !== radio.name"><i class="fas fa-minus"></i></md-button>
                            </div>

                            <div class="md-layout-item md-xsmall-size-100 md-small-size-80 md-medium-size-90 md-large-size-90 md-xlarge-size-90 div-remove">
                                <el-input v-model="blackListItemUpdate" placeholder="Novo item"></el-input>
                                <md-button class="md-success btn-remove" @click="addBlackListUpdate()"><i class="fas fa-plus"></i></md-button>
                            </div>
                        </div>
                        <div class="md-layout divButton">
                            <div
                                class="md-layout-item md-small-size-100 md-medium-size-30 md-large-size-30 md-xlarge-size-20">
                                <md-button class="md-info md-block" @click="updateBlackList()">
                                    Salvar</md-button>
                            </div>
                        </div>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </el-dialog>

        <newSocialNetwork />
    </div>
</template>

<script>
    import {
        StatsCard,
        ChartCard
    } from '@/components'
    import mixpanelAdapter from '../../../utils/events/mixpanelAdapter'
    import selectRadio from '../../../utils/events/select-radio.js'
    import clearCacheRadio from './../../../utils/events/clearRadioCache.js'
    import newSocialNetwork from './NewSocialNetworks'
    import services from '../../../routes/services'
    import {
        Dialog,
        Button,
        Tabs,
        TabPane,
        Input,
        Step,
        Steps,
        Checkbox,
        CheckboxGroup
    } from 'element-ui'
    import Swal from 'sweetalert2'
    import Axios from 'axios';
    import models from '../../../routes/models.js';
    import NewUser from './NewUser';
    const xml2js = require('xml2js');
    import jwt_decode from "jwt-decode";
    export default {
        components: {
            ChartCard,
            StatsCard,
            [Button.name]: Button,
            [Dialog.name]: Dialog,
            [Tabs.name]: Tabs,
            [TabPane.name]: TabPane,
            [Input.name]: Input,
            [Steps.name]: Steps,
            [Step.name]: Step,
            [Checkbox.name]: Checkbox,
            [CheckboxGroup.name]: CheckboxGroup,
            newSocialNetwork,
			NewUser
        },
        data() {
            return {
                key: jwt_decode(localStorage.getItem('APP_01')).radio.key,
                user: jwt_decode(localStorage.getItem('SET_APP')).user[0],
                radioId: jwt_decode(localStorage.getItem('APP_01')).radio.id,
                radio: {
                    images: {},
                    admob: {
                        android: {},
                        ios: {},
                        admob_interstitial_blocked: 0
                    },
                    store_link: {
                        android: "",
                        ios: ""
                    },
                    service_account: "",
                    xml: {
                        type: ''
                    }
                },
                pushNotification: {
                    server_key: '',
                    device_token: '',
                    title: '',
                    message: ''
                },
                currentStreamingPlatformsBlocked: [],
                previousStreamingPlatformsBlocked: [],
                dialogVisible: false,
                logoToCloudinary: null,
                backgroundToCloudinary: null,
                image: false,
                background: false,
                urlLogo: '',
                urlBackground: '',
                loading: true,
                buttonConfigDisabled: true,
                xmlString: 'Carregando...',
                xmlObject: '',
                activeName: 'music',
                joinXMLHTML: [],
                joinXMLHTMLJSON: [],
                joinXMLHTML2: '',
                showPanel: false,
                tagPrincipal: '',
                tagMusicName: '',
                tagArtistName: '',
                tagCoverLink: '',
                checkTag2: true,
                checkTag3: false,
                alertMsg2: false,
                alertMsg3: false,
                uncheck: false,
                currentNode: '',
                strTagPrincipal: '',
                strMusicName: '',
                strArtistName: '',
                strCoverLink: '',
                divData: '',
                showConfig: true,
                blockTabMusic: false,
                blockTabArtist: true,
                blockTabCover: true,
                blockBlackList: true,
                blockTabValidation: true,
                userClient: true,
                arrBlackList: [],
                blackListItem: '',
                showPanelBlackList: false,
                activeBlackList: 'tab-blackList',
                arrUpdateBlackList: [],
                blackListItemUpdate: '',
                arrBlackListStr: '',
                active1: 0,
                ipAddress: '',
                msgComponentImage: '',
                msgComponentImageLogo: ''
            }
        },
        methods: {
            sendMixPanelEvent(eventString, params){
                mixpanelAdapter.track(eventString, {
                    ...params,
                    screen_name: this.$route.name
                })
            },
            sendPushToDevice(){
                Swal.showLoading()
                const formData = {
                    key: this.key,
                    server_key: this.pushNotification.server_key,
                    device_token: this.pushNotification.device_token,
                    title: this.pushNotification.title,
                    message: this.pushNotification.message
                }
                models().post('/services/sendToDevice', formData).then(() => {
                    Swal(
                        'Push enviado!',
                        'Verifique seu dispositivo',
                        'success'
                    )
                }).catch((err)=>{
                    Swal(
                        'Erro!',
                        'Houve algum erro ao enviar o push',
                        'error'
                    )
                })
            },
            next() {
                if (this.active1++ > 5) {
                    this.active1 = 0;
                }
                document.getElementsByClassName("el-dialog__wrapper")[0].scrollTo(0,0);
            },
            previous() {
                if (this.active1-- < 0) this.active1 = 0;
                document.getElementsByClassName("el-dialog__wrapper")[0].scrollTo(0,0);
            },
            getDataRadio() {
                this.loading = true
                this.arrBlackList = []
                models().get(`radios/appGetAll?key=${this.key}`).then((resp) => {
                        this.radio = resp.data[0]
                        this.getBlacklist(resp.data[0].name)
                        this.userClient = jwt_decode(localStorage.getItem('APP_01')).radio.key.pagarme
                        this.loading = false
                    })
                    .catch((e) => {
                        console.log(e)
                        Swal({
                            title: "Atenção",
                            confirmButtonText: `Enviar Email`,
                            cancelButtonText: "Cancelar",
                            showCancelButton: true,
                            html: `Não foi possível obter os dados de configuração da sua rádio. Por favor entre em contato com nosso suporte no email: <a href = "mailto:suporte@mobradio.com.br?&subject=Erro%20Painel%20${jwt_decode(localStorage.getItem('APP_01')).radio.name}&body=Codigo-ACTIVE_0">suporte@mobradio.com.br</a>. <BR> Informe o código: <b>ACTIVE_0<b>`,
                            type: "error"
                        }).then((result) => {
                            if (result.value) {
                                window.open("mailto:suporte@mobradio.com.br?&subject=Erro%20Painel%20${jwt_decode(localStorage.getItem('APP_01')).radio.name}&body=Codigo-ACTIVE_0")
                                this.$router.push('/dashboard')
                            } else {
                                this.$router.push('/dashboard')
                            }
                        })
                    })
            },
            updateDataRadio() {
                Swal.fire({
                    title: 'Atualizando',
                    html: 'Aguarde enquanto processamos todas as informações...',
                    onOpen: () => {
                        Swal.showLoading()
                        const formData = {
                            key: this.radio.key,
                            name: this.radio.name,
                            image_logo_url: '',
                            image_background_url: '',
                            phone_number: this.radio.phone_number,
                            email: this.radio.email,
                            site: this.radio.site,
                            service_account: this.radio.service_account,
                            admob_app_id_ios: this.radio.admob.ios.app_id,
                            admob_banner_ios: this.radio.admob.ios.banner,
                            admob_interstitial_ios: this.radio.admob.ios.interstitial,
                            admob_app_id_android: this.radio.admob.android.app_id,
                            admob_banner_android: this.radio.admob.android.banner,
                            admob_interstitial_android: this.radio.admob.android.interstitial,
                            cloudinary_cloud_name: this.radio.cloudinary_cloud_name ? this.radio.cloudinary_cloud_name : "",
                            cloudinary_api_key: this.radio.cloudinary_api_key ? this.radio.cloudinary_api_key : "",
                            cloudinary_api_secret: this.radio.cloudinary_api_secret ? this.radio.cloudinary_api_secret : "",
                            social_networks: this.radio.social_networks,
                            streaming: this.radio.streaming,
                            store_link_android: this.radio.store_link.android,
                            store_link_ios: this.radio.store_link.ios,
                            admob_interstitial_blocked: this.radio.admob.admob_interstitial_blocked
                        }

                        this.updateStreamingPlatforms()

                        if (this.logoToCloudinary !== null && this.backgroundToCloudinary !== null) {
                            Promise.resolve(this.UpdateImageCloudinary(this.logoToCloudinary, this.radio
                                    .images.logo)).then((resp) => {
                                    formData.image_logo_url = resp.secure_url
                                    Promise.resolve(this.UpdateImageCloudinary(this
                                            .backgroundToCloudinary, this.radio.images.background))
                                        .then((resp) => {
                                            formData.image_background_url = resp.secure_url
                                            models().put('radios/UpdateRadio', formData).then((
                                                resp) => {
                                                    const radio = (jwt_decode(localStorage.getItem('APP_01'))).radio

                                                    if(radio.parent_id){
                                                        clearCacheRadio(radio.parent_id,radio.master_key)
                                                    }
                                                    clearCacheRadio(radio.id, radio.master_key)

                                                    Swal(
                                                        'Pronto',
                                                        'Os dados da rádio foram atualizados com sucesso!',
                                                        'success'
                                                    )
                                                    this.sendMixPanelEvent(`radio_data_updated`)
                                                    this.deleteCacheApi()
                                                    this.image = false                        
                                                })
                                                .catch((e) => {
                                                    console.log(e)
                                                    Swal(
                                                        'Falha',
                                                        'Não foi possível atualizar os dados da rádio, por favor tente novamente.',
                                                        'error'
                                                    )
                                                })
                                        })
                                        .catch((e) => {
                                            console.log(e)
                                            Swal(
                                                'Falha',
                                                'Não foi possível atualizar o background da rádio, por favor tente novamente.',
                                                'error'
                                            )
                                        })
                                })
                                .catch((e) => {
                                    console.log(e)
                                    Swal(
                                        'Falha',
                                        'Não foi possível atualizar a logo da rádio, por favor tente novamente.',
                                        'error'
                                    )
                                })
                        } else if (this.logoToCloudinary !== null && this.backgroundToCloudinary === null) {
                            Promise.resolve(this.UpdateImageCloudinary(this.logoToCloudinary, this.radio
                                    .images.logo)).then((resp) => {
                                    formData.image_logo_url = resp.secure_url
                                    formData.image_background_url = this.radio.images.background

                                    models().put('radios/UpdateRadio', formData).then((resp) => {
                                            const radio = (jwt_decode(localStorage.getItem('APP_01'))).radio
                                            
                                            if(radio.parent_id){
                                                clearCacheRadio(radio.parent_id,radio.master_key)
                                            }
                                            clearCacheRadio(radio.id, radio.master_key)
                                            Swal(
                                                'Pronto',
                                                'Os dados da rádio foram atualizados com sucesso!',
                                                'success'
                                            )
                                            this.sendMixPanelEvent(`radio_data_updated`)
                                            this.deleteCacheApi()
                                            this.image = false
                                        })
                                        .catch((e) => {
                                            console.log(e)
                                            Swal(
                                                'Falha',
                                                'Não foi possível atualizar os dados da rádio, por favor tente novamente.',
                                                'error'
                                            )
                                        })
                                })
                                .catch((e) => {
                                    console.log(e)
                                    Swal(
                                        'Falha',
                                        'Não foi possível atualizar a logo da rádio, por favor tente novamente.',
                                        'error'
                                    )
                                })
                        } else if (this.logoToCloudinary === null && this.backgroundToCloudinary !== null) {
                            Promise.resolve(this.UpdateImageCloudinary(this.backgroundToCloudinary, this
                                .radio.images.background)).then((resp) => {
                                formData.image_logo_url = this.radio.images.logo
                                formData.image_background_url = resp.secure_url
                                models().put('radios/UpdateRadio', formData).then((resp) => {
                                        const radio = (jwt_decode(localStorage.getItem('APP_01'))).radio
                                        
                                        if(radio.parent_id){
                                            clearCacheRadio(radio.parent_id,radio.master_key)
                                        }
                                        clearCacheRadio(radio.id, radio.master_key)
                                        Swal(
                                            'Pronto',
                                            'Os dados da rádio foram atualizados com sucesso!',
                                            'success'
                                        )
                                        this.sendMixPanelEvent(`radio_data_updated`)
                                        this.deleteCacheApi()
                                        this.image = false
                                    })
                                    .catch((e) => {
                                        console.log(e)
                                        Swal(
                                            'Falha',
                                            'Não foi possível atualizar os dados da rádio, por favor tente novamente.',
                                            'error'
                                        )
                                    })
                            })
                        } else {
                            formData.image_logo_url = this.radio.images.logo
                            formData.image_background_url = this.radio.images.background
                            models().put('radios/UpdateRadio', formData).then((resp) => {
                                    const radio = (jwt_decode(localStorage.getItem('APP_01'))).radio
                                    
                                    if(radio.parent_id){
                                        clearCacheRadio(radio.parent_id,radio.master_key)
                                    }
                                    clearCacheRadio(radio.id, radio.master_key)
                                    Swal(
                                        'Pronto',
                                        'Os dados da rádio foram atualizados com sucesso!',
                                        'success'
                                    )
                                    this.sendMixPanelEvent(`radio_data_updated`)
                                    this.deleteCacheApi()
                                    this.image = false
                                })
                                .catch((e) => {
                                    console.log(e)
                                    Swal(
                                        'Falha',
                                        'Não foi possível atualizar os dados da rádio, por favor tente novamente.',
                                        'error'
                                    )
                                })
                        }
                    }
                })
            },
            getCurrentStreamingPlatformsBlocked(){
                this.currentStreamingPlatformsBlocked = []
                this.previousStreamingPlatformsBlocked = []
                models().get(`/radios/getStreamingBlocked/${this.key}`).then(res => {
                    const radiosBlocked = res.data.radios_blocked.map( element => element.platform)
                    this.currentStreamingPlatformsBlocked = [...radiosBlocked]
                    this.previousStreamingPlatformsBlocked = [...radiosBlocked]
                }).catch((err)=>{
                    console.log(err)
                    Swal.fire({
                        title: 'Ops!',
                        text: "Não foi possível recuperar as plataformas de streaming bloqueadas.",
                        type: 'error'
                    })
                })
            },
            blockStreamingPlatform(platform){
                return new Promise((resolve, reject) => {
                    models().put(`/radios/blockStreamingPlatform`, {
                        appKey: this.key,
                        platform: platform
                    }).then(()=>{
                        resolve()
                    })
                    .catch((err)=>{
                        console.log(err)
                        Swal.fire({
                            title: 'Ops!',
                            text: "Não foi possível bloquear o streaming.",
                            type: 'error'
                        })
                        reject()
                    })
                })
            },
            unblockStreamingPlatform(platform){
                return new Promise((resolve, reject) => {
                    models().put(`/radios/unblockStreamingPlatform`, {
                        appKey: this.key,
                        platform: platform
                    }).then(()=>{
                        resolve()
                    }).catch((err)=>{
                        console.log(err)
                        Swal.fire({
                            title: 'Ops!',
                            text: "Não foi possível bloquear o streaming.",
                            type: 'error'
                        })
                        reject()
                    })
                })
            },
            updateStreamingPlatforms(){
                const platformsToBlock = this.currentStreamingPlatformsBlocked.filter(item => { 
                    return this.currentStreamingPlatformsBlocked.includes(item) && 
                            !this.previousStreamingPlatformsBlocked.includes(item);
                })

                const platformsToUnblock = this.previousStreamingPlatformsBlocked.filter(item => { 
                    return this.previousStreamingPlatformsBlocked.includes(item) && 
                            !this.currentStreamingPlatformsBlocked.includes(item);
                })

                const promisesBlocked = []
                const promisesUnblocked = []

                for (let platform of platformsToUnblock) {
                    promisesUnblocked.push(this.unblockStreamingPlatform(platform))
                }

                for (let platform of platformsToBlock) {
                    if(!this.previousStreamingPlatformsBlocked.includes(platform)){
                        promisesBlocked.push(this.blockStreamingPlatform(platform))
                    }
                }

                Promise.all([...promisesBlocked, ...promisesUnblocked]).then(res => {
                    this.getCurrentStreamingPlatformsBlocked()
                })
            },
            UpdateImageCloudinary: function (file, updateUrl) {
                const urlCloud = 'https://api.painel.mobradio.com.br/services/uploadCloudImage'
                const formData = new FormData()
                const config = {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                }
                if (!updateUrl) {
                    formData.append('key', this.radio.key)
                    formData.append('attachments', file)
                } else {
                    const aux = updateUrl.split("/")
                    const newUrl = `${aux[9]}`.split(".")[0]

                    formData.append('key', this.radio.key)
                    formData.append('attachments', file)
										formData.append('public_id', newUrl)
										formData.append('cloud_name', this.radio.cloudinary_cloud_name)
										formData.append('cloud_key', this.radio.cloudinary_api_key)
										formData.append('cloud_secret', this.radio.cloudinary_api_secret)
                }

                return services().post('services/uploadCloudImage', formData, config).then(res => {
                    return res.data
                })
            },
            onFileChangeLogo(e) {

                this.msgComponentImage = ''
                var files = e.target.files || e.dataTransfer.files;
                if(files[0].size <= 250000) {
                    this.logoToCloudinary = files[0]
                    this.createLogo(files[0]);
                } else {
                    this.logoToCloudinary = null
                    this.msgComponentImageLogo = "O tamanho da imagem não pode ser maior que 250kb"
                }
            },
            onFileChangeBackground(e) {
                this.msgComponentImage = ''
                var files = e.target.files || e.dataTransfer.files;
                if(files[0].size <= 250000) {
                    this.backgroundToCloudinary = files[0]
                    this.createBackground(files[0]);
                } else {
                    this.backgroundToCloudinary = null
                    this.msgComponentImage = "O tamanho da imagem não pode ser maior que 250kb"
                }
            },
            createLogo(file) {
                var reader = new FileReader();
                var vm = this;
                reader.onload = (e) => {
                    vm.image = e.target.result;
                };
                reader.readAsDataURL(file);
            },
            createBackground(file) {
                var reader = new FileReader();
                var vm = this;
                reader.onload = (e) => {
                    vm.background = e.target.result;
                };
                reader.readAsDataURL(file);
            },
            getXmlOrJsonData(xmlLink) {
                this.tagPrincipal = ''
                this.tagMusicName = ''
                this.tagArtistName = ''
                this.tagCoverLink = ''
                this.strPrincipal = ''
                this.strMusicName = ''
                this.strArtistName = ''
                this.strCoverLink = ''
                this.joinXMLHTML = []
                this.removeAllClassActive()
                let self = this
                self.step(0)
                self.showPanel = false
                Swal.fire({
                    title: 'Aguarde',
                    html: 'Buscando informações do XML...',
                    type: 'warning',
                    onOpen: () => {
                        Swal.showLoading()
                    }
                })

                let str = xmlLink.split('/').slice(-1)[0]
                if(str.includes("xml")) {
                    Axios.get(`https://api.mobradio.com.br/v1/remote?url=${xmlLink}`).then((resp) => {
                        xml2js.parseString(resp.data, function (err, result) {
                            self.xmlString = resp.data
                            self.divData = ""
                            self.joinXMLHTML = []
                            self.iterate(result, '')
                            self.joinXMLHTML.push(self.divData);
                            self.radio.xml.type = 0
                            Swal.close()
                            self.showPanel = true
                            self.showConfig = !self.showConfig
                        });
                    })
                    .catch((e) => {
                        this.showPanel = false
                        Swal(
                            'Falha',
                            'Houve um problema ao buscar os dados do XML, por favor tente novamente.',
                            'error'
                        )
                        console.log(e)
                    })
                } else if(str.includes("json")) {
                    Axios.get(`https://api.mobradio.com.br/v1/remote?url=${xmlLink}&type=json`).then((resp) => {
                        let obj = resp.data
                        if(typeof(obj) !== "object") {
                            obj = JSON.parse(resp.data)
                        }

                        self.xmlString = resp.data
                        self.divData = ""
                        self.joinXMLHTML = []
                        self.iterateJSON(obj, '')
                        self.joinXMLHTML.push(self.divData);
                        self.radio.xml.type = 1
                        Swal.close()
                        self.showPanel = true
                        self.showConfig = !self.showConfig
                    })
                    .catch((e) => {
                        this.showPanel = false
                        Swal(
                            'Falha',
                            'Houve um problema ao buscar os dados do XML, por favor tente novamente.',
                            'error'
                        )
                        console.log(e)
                    })
                } else if(!str.includes("xml") && !str.includes("json")) {
                    Axios.get(`${xmlLink}`).then((resp) => {
                        let obj = resp.data
                        if(typeof(obj) !== "object") {
                            xml2js.parseString(resp.data, function (err, result) {
                                self.xmlString = resp.data
                                self.divData = ""
                                self.joinXMLHTML = []
                                self.iterate(result, '')
                                self.joinXMLHTML.push(self.divData);
                                self.radio.xml.type = 0
                                Swal.close()
                                self.showPanel = true
                                self.showConfig = !self.showConfig
                            });
                        } else {
                            self.xmlString = resp.data
                            self.divData = ""
                            self.joinXMLHTML = []
                            self.iterateJSON(obj, '')
                            self.joinXMLHTML.push(self.divData);
                            self.radio.xml.type = 1
                            Swal.close()
                            self.showPanel = true
                            self.showConfig = !self.showConfig
                        }
                    })
                    .catch((e) => {
                        this.showPanel = false
                        Swal(
                            'Falha',
                            'Houve um problema ao buscar os dados do XML, por favor tente novamente.',
                            'error'
                        )
                        console.log(e)
                    })
                }
                else {
                    Swal(
                        'Falha',
                        'URL inválida, verifique se a mesma é um XML ou JSON.',
                        'error'
                    )
                }
            },
            SelectXmlMusic(e) {
                let element = e.target
                if (e.target.nodeName == "SPAN") {
                    element = e.target.parentElement
                }
                this.joinXMLHTML2 = element.parentElement.outerHTML
                if (element.childNodes[1]) {
                    this.removeAllClassActive()
                    const divs = document.getElementById('divsXml1').getElementsByTagName('div')
                    const str = element.getAttribute('path').split('|')
                    const sliceStr = str.slice(0, -1)
                    this.tagPrincipal = sliceStr.join('|').trim()
                    this.strTagPrincipal = sliceStr.join(' -> ').replace(/-> 0/g, '').trim()
                    this.strMusicName = str.pop()
                    this.tagMusicName = str.join('|').trim()

                    for (let div of divs) {
                        div.classList.remove("select-xml", "ck-item")
                    }
                    element.classList.add("select-xml", "ck-item")
                }
            },
            SelectXmlArtist(e) {
                let element = e.target
                if (e.target.nodeName == "SPAN") {
                    element = e.target.parentElement
                }

                if (element.childNodes[1] && !element.classList.contains("selected-xml-tag")) {
                    const divs = document.getElementById('divsXml2').getElementsByTagName('div')
                    const str = element.getAttribute('path').split('|')
                    const sliceStr = str.slice(0, -1)
                    const joinStr = sliceStr.join('|').trim()
                    this.strArtistName = str.pop()
                    this.tagArtistName = str.join('|').trim()


                    for (let div of divs) {
                        div.classList.remove("select-xml", "ck-item")
                    }
                    element.classList.add("select-xml", "ck-item")

                    if (joinStr !== '' && (joinStr !== this.tagPrincipal)) {
                        this.checkTag2 = false
                        this.alertMsg2 = false
                    } else {
                        this.checkTag2 = true
                        this.alertMsg2 = true
                    }
                }
            },
            SelectXmlCover(e) {
                let element = e.target
                if (e.target.nodeName == "SPAN") {
                    element = e.target.parentElement
                }
                if (element.childNodes[1] && !element.classList.contains("selected-xml-tag")) {
                    const divs = document.getElementById('divsXml3').getElementsByTagName('div')
                    const str = element.getAttribute('path').split('|')
                    const sliceStr = str.slice(0, -2)
                    const joinStr = sliceStr.join('|').trim()
                    this.strCoverLink = str.pop()
                    this.tagCoverLink = str.join('|').trim()


                    for (let div of divs) {
                        div.classList.remove("select-xml", "ck-item")
                    }
                    element.classList.add("select-xml", "ck-item")

                    if (joinStr !== "") {
                        this.uncheck = true
                    } else {
                        this.uncheck = false
                    }

                    if (joinStr !== '' && (joinStr == this.tagPrincipal)) {
                        this.checkTag3 = false
                        this.alertMsg3 = false
                    } else {
                        this.checkTag3 = true
                        this.alertMsg3 = true
                    }
                }
            },
            unCheck() {
                const divs = document.getElementById('divsXml3').getElementsByTagName('div')
                this.strCoverLink = ''
                this.tagCoverLink = ''
                this.checkTag3 = false
                this.alertMsg3 = false
                for (let div of divs) {
                    div.classList.remove("select-xml", "ck-item")
                }
                this.uncheck = false
            },
            iterate(obj, stack) {
                let self = this
                for (var property in obj) {
                    if (obj.hasOwnProperty(property)) {
                        if (typeof obj[property] == "object") {
                            self.iterate(obj[property], stack + '' + property + '|');
                        } else {
                            stack = stack.substring(0, stack.length - 1); //retirar último pipe
                            if (obj[property] !== '') {
                                let array = stack.split('|')
                                let lastObj = array.pop()
                                let aux = array.join(' -> ')

                                if (self.currentNode !== aux) {
                                    self.currentNode = aux;
                                    self.divData += `<b class="txt-xml">XML -> ${aux.replace(/-> 0/g,'')}</b>`;
                                    self.divData += `<div class="div-xml" path="${stack}|${obj[property]}"><span style="color:purple">${lastObj}:</span> ${obj[property]}</div>`
                                } else {
                                    self.divData +=
                                        `<div class="div-xml" path="${stack}|${obj[property]}"><span style="color:purple">${lastObj}:</span> ${obj[property]}</div>`
                                }
                            }
                        }
                    }
                }
            },
            iterateJSON(obj, stack) {
                let self = this
                for (let property in obj) {
                    if (obj.hasOwnProperty(property)) {
                        if (typeof obj[property] == "object") {
                            self.iterateJSON(obj[property], stack + '' + property + '|')
                        } else {
                            if(property != "0") {
                                let auxNode = stack + property
                                if (obj[property] !== '') {
                                    let array = auxNode.split('|')
                                    let lastObj = array.pop()
                                    let aux = array.join(' -> ')
                                    if (self.currentNode !== aux) {
                                        self.currentNode = aux;
                                        self.divData += `<b class="txt-xml">XML -> ${aux.replace(/-> 0/g,'')}</b>`;
                                        self.divData += `<div class="div-xml" path="${auxNode}|${obj[property]}"><span style="color:purple">${lastObj}:</span> ${obj[property]}</div>`
                                    } else {
                                        self.divData +=
                                            `<div class="div-xml" path="${auxNode}|${obj[property]}"><span style="color:purple">${lastObj}:</span> ${obj[property]}</div>`
                                    }
                                }
                            } else {
                                stack = stack.substring(0, stack.length - 1); //retirar último pipe

                                if (obj[property] !== '') {
                                    let array = stack.split('|')
                                    let lastObj = array.pop()
                                    let aux = array.join(' -> ')
                                    if (self.currentNode !== aux) {
                                        self.currentNode = aux;
                                        self.divData += `<b class="txt-xml">XML -> ${aux.replace(/-> 0/g,'')}</b>`;
                                        self.divData += `<div class="div-xml" path="${stack}|${obj[property]}"><span style="color:purple">${lastObj}:</span> ${obj[property]}</div>`
                                    } else {
                                        self.divData +=
                                            `<div class="div-xml" path="${stack}|${obj[property]}"><span style="color:purple">${lastObj}:</span> ${obj[property]}</div>`
                                    }
                                }
                            }

                        }
                    }
                }
            },
            step(id) {
                let self = this
                switch (id) {
                    case 0:
                        self.activeName = 'music'
                        self.blockTabMusic = false
                        self.blockTabArtist = true
                        self.blockTabCover = true
                        self.blockBlackList = true
                        self.blockTabValidation = true
                        break;
                    case 1:
                        // self.activeName = 'artist'
                        // self.blockTabMusic = true
                        // self.blockTabArtist = false
                        // self.blockTabCover = true
                        // self.blockBlackList = true
                        // self.blockTabValidation = true
                        const divsMusic = document.getElementById('divsXml2').getElementsByTagName('div')
                        console.log(divsMusic)
                        for (let div of divsMusic) {
                            if (div.getAttribute('path') === this.tagMusicName + '|' + this.strMusicName) {
                                div.classList.add("selected-xml-tag", "selected-xml-tag-item")
                                div.classList.remove("select-xml", "ck-item")
                            }
                        }
                        break;
                    case 2:
                        self.activeName = 'cover'
                        self.blockTabMusic = true
                        self.blockTabArtist = true
                        self.blockTabCover = false
                        self.blockBlackList = true
                        self.blockTabValidation = true
                        const divsArtist = document.getElementById('divsXml3').getElementsByTagName('div')
                        for (let div of divsArtist) {
                            if (div.getAttribute('path') === this.tagMusicName + '|' + this.strMusicName ||
                                div.getAttribute('path') === this.tagArtistName + '|' + this.strArtistName) {
                                div.classList.add("selected-xml-tag", "selected-xml-tag-item")
                                div.classList.remove("select-xml", "ck-item")
                            }
                        }
                        break;
                    case 3:
                        self.blockTabMusic = true
                        self.blockTabArtist = true
                        self.blockTabCover = true
                        self.blockBlackList = false
                        self.blockTabValidation = true
                        self.activeName = 'blacklist'
                        break;
                    case 4:
                        self.blockTabMusic = true
                        self.blockTabArtist = true
                        self.blockTabCover = true
                        self.blockBlackList = true
                        self.blockTabValidation = false
                        self.activeName = 'validation'
                        break;

                    default:
                        break;
                }

            },
            removeAllClassActive() {
                const els = document.getElementsByClassName('div-xml')
                for (var i = 0; i < els.length; i++) {
                    els[i].classList.remove("select-xml", "ck-item")
                }
                this.tagPrincipal = ''
                this.musicName = ''
                this.artistName = ''
                this.coverLink = ''
                this.checkTag2 = true
                this.uncheck = false
            },
            finish() {
                let promises = []
                Swal.fire({
                    title: "Aguarde",
                    html: "Estamos salvando as informações",
                    onOpen: () => {
                        Swal.showLoading()
                    }
                })
                let self = this
                this.showPanel = false
                let dataForm = {
                    key: self.key,
                    xml_link: self.radio.xml.link,
                    xml_title_tag: self.tagMusicName,
                    xml_artist_tag: self.tagArtistName,
                    xml_cover_tag: self.tagCoverLink,
                    xml_link_type: self.radio.xml.type
                }
                models().put('radios/updateXML', dataForm)
                    .then((resp) => {
                        models().delete(`radios/deleteBlackList/${this.key}`).then(() => {
                            for(let item of this.arrBlackList) {
                                promises.push(Promise.resolve(this.postBlackList(item.name)))
                            }
                            Promise.all(promises).then(() => {
                                this.getBlacklist(this.radio.name)
                                Swal(
                                    'Pronto',
                                    'Os dados da música foram configurados com sucesso.',
                                    'success'
                                )

                                this.sendMixPanelEvent(`updated_xml`)
                            })
                        })
                        .catch((e) => {
                            Swal(
                                'Erro',
                                'Tivemos um problema ao atualizar a URL dos dados da música. Por favor tente novamente.',
                                'error'
                            )
                            console.log(e)
                        })
                    })
                    .catch((e) => {
                        console.log(e)
                        Swal(
                            'Erro',
                            'Tivemos um problema ao atualizar a URL dos dados da música. Por favor tente novamente.',
                            'error'
                        )
                    })
            },
            copyToClipBoard() {
                var tooltip = document.getElementById("myTooltip");
                Clipboard.copy(this.key);
                tooltip.innerHTML = "Copiado!";
            },
            outClipBoard() {
                var tooltip = document.getElementById("myTooltip");
                tooltip.innerHTML = "Clique para copiar";
            },
            copyToClipBoard2() {
                var tooltip = document.getElementById("myTooltip2");
                Clipboard.copy(this.radio.share_link);
                tooltip.innerHTML = "Copiado!";
            },
            outClipBoard2() {
                var tooltip = document.getElementById("myTooltip2");
                tooltip.innerHTML = "Clique para copiar";
            },
            addBlackList() {
                let aux = []
                if(this.blackListItem !== '') {
                    for(let item of this.arrBlackList) {
                        aux.push(item.name.toLowerCase())
                    }

                    if(!aux.includes(this.blackListItem.toLowerCase())) {
                        this.arrBlackList.push({"name": this.blackListItem})
                        this.blackListItem = ''
                    } else {
                        this.blackListItem = ''
                    }
                }
            },
            removeBlackList(index) {
                this.arrBlackList.splice(index, 1)
            },
            async postBlackList(name) {
                let formData = {
                    appKey: this.key,
                    name: name
                }

                this.sendMixPanelEvent(`blacklist_updated`)
                return await models().post("radios/insertBlackList", formData)
            },
            getBlacklist(radioName) {
                this.arrBlackList = []
                this.arrUpdateBlackList = []
                models().get(`radios/getBlacklist?appKey=${this.key}`).then((resp) => {
                    if(resp.data.length > 0) {
                        this.arrBlackListStr = '';
                        for(let item of resp.data) {
                            this.arrBlackList.push({"name": item.name})
                            this.arrUpdateBlackList.push({"name": item.name})
                            this.arrBlackListStr += item.name + ', ';
                        }
                        this.arrBlackListStr = this.arrBlackListStr.substring(0,this.arrBlackListStr.length-2)
                    } else {
                        this.arrBlackList.push({"name": radioName})
                        this.arrUpdateBlackList.push({"name": radioName})
                        this.arrBlackListStr = radioName;
                    }
                })
            },
            editBlackList() {
                this.showPanelBlackList = true
                // this.arrUpdateBlackList = this.arrBlackList
            },
            addBlackListUpdate() {
                let aux = []
                if(this.blackListItemUpdate !== '') {
                    for(let item of this.arrUpdateBlackList) {
                        aux.push(item.name.toLowerCase())
                    }

                    if(!aux.includes(this.blackListItemUpdate.toLowerCase())) {
                        this.arrUpdateBlackList.push({"name": this.blackListItemUpdate})
                        this.blackListItemUpdate = ''
                    } else {
                        this.blackListItemUpdate = ''
                    }
                }
            },
            removeBlackListUpdate(index) {
                this.arrUpdateBlackList.splice(index, 1)
            },
            updateBlackList() {
                this.showPanelBlackList = false
                let promises = []
                Swal.fire({
                    title: "Aguarde",
                    html: "Atualizando Blacklist",
                    onOpen: () => {
                        Swal.showLoading()
                    }
                })
                models().delete(`radios/deleteBlackList/${this.key}`).then(() => {
                    for(let item of this.arrUpdateBlackList) {
                        promises.push(Promise.resolve(this.postBlackList(item.name)))
                    }

                    Promise.all(promises).then(() => {
                        this.getBlacklist(this.radio.name)
                        Swal(
                            'Pronto',
                            'Blacklist atualizada com sucesso',
                            'success'
                        )
                    })
                })
                .catch((e) => {
                    Swal(
                        'Erro',
                        'Tivemos um problema ao atualizar o XML. Por favor tente novamente.',
                        'error'
                    )
                    console.log(e)
                })
            },
            deleteCacheApi() {
                // Deleta o cache na API EXTERNA
                Axios.get(`https://server.mobradio.com.br/brokers/clearRadioCache/${this.radioId}`)
                .then(response => console.log(response.data))
                .catch(e => console.log(e))
            },
            openNewSocialNetwork() {
                this.$emit("openNewSocialNetwork", true)
            },
            deleteSocialNetwork(social_netword_id) {
                Swal.fire({
                    title: 'Atenção',
                    text: "Deseja realmente apagar essa rede social?",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Confirmar',
                    cancelButtonText: 'Cancelar'
                }).then((result) => {
                    if (result.value) {
                        models().delete(`radios/deleteSocialNetwork?radio_id=${this.radioId}&social_network_id=${social_netword_id}`)
                        .then((resp) => {
                            this.sendMixPanelEvent(`social_media_deleted`, {
                                social_media_name: this.radio.social_networks.filter(social_media=> social_media.id === social_netword_id)[0].name
                            })
                            const radio_change_log_form = {
                                radio_key: this.radio.key,
                                user_id: this.user.id,
                                user_username: this.user.username,
                                user_first_name: this.user.firstname,
                                user_lastname: this.user.lastname,
                                user_email: this.user.email,
                                radio_data: JSON.stringify(this.radio.social_networks.splice( this.radio.social_networks.indexOf(social_netword_id), 1 )).replace(/['"]+/g, "'"),
                                type: 2,
                                user_agent: navigator.userAgent
                            }
                            this.radioChangeLog(radio_change_log_form)
                            Swal.fire(
                                'Pronto',
                                'Rede social deletada!',
                                'success'
                            )
                        })
                        .catch((e) => {
                            console.log(e)
                        })
                    }
                })
            },
            radioChangeLog(radio_change_log_form) {
                models().post('radios/radioChangeLog', radio_change_log_form)
            }
        },
        mounted() {
            this.getDataRadio()

            if(this.user.level == 1 || this.user.level == 0){
                this.getCurrentStreamingPlatformsBlocked()
            }

            selectRadio.$on('selectRadio', (payload) => {
                this.radioId = payload.id
                this.key = payload.key
                
                if(this.user.level == 1 || this.user.level == 0){
                    this.getCurrentStreamingPlatformsBlocked()
                }
            })
            window.Clipboard = (function (window, document, navigator) {
                var textArea,
                    copy;

                function isOS() {
                    return navigator.userAgent.match(/ipad|iphone/i);
                }

                function createTextArea(text) {
                    textArea = document.createElement('textArea');
                    textArea.value = text;
                    document.body.appendChild(textArea);
                }

                function selectText() {
                    var range,
                        selection;

                    if (isOS()) {
                        range = document.createRange();
                        range.selectNodeContents(textArea);
                        selection = window.getSelection();
                        selection.removeAllRanges();
                        selection.addRange(range);
                        textArea.setSelectionRange(0, 999999);
                    } else {
                        textArea.select();
                    }
                }

                function copyToClipboard() {
                    document.execCommand('copy');
                    document.body.removeChild(textArea);
                }

                copy = function (text) {
                    createTextArea(text);
                    selectText();
                    copyToClipboard();
                };

                return {
                    copy: copy
                };
            })(window, document, navigator);

            this.$root.$on("pushSocialNetwork", (payload) => {
                this.radio.social_networks.push({
                    id: payload.id,
                    link: payload.link,
                    name: payload.name
                });
            })
        },
        watch: {
            key(newKey) {
                this.getDataRadio()
            },
            showPanel(val) {
                let self = this
                if (val == false) {
                    self.showConfig = true
                }
            }
        }
    }
</script>

<style>

    .label-streaming-checkbox {
        display: flex;
        align-items: center;
        margin-right: 10px;
        max-width: 100px;
    }

    .icon-checkbox {
        height: 20px !important;
        width: 20px !important;
    }

    .label-streaming-checkbox span {
        margin: 0;
        margin-left: 6px;
        font-size: 14px;
        font-weight: 600;
        color: #00000083;
        text-wrap: nowrap;
    }

    .radio-key>input:hover {
        cursor: not-allowed !important;
    }

    .tooltip {
        position: relative;
        display: inline-block;
    }

    .tooltip .tooltiptext {
        visibility: hidden;
        width: 140px;
        background-color: #555;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px;
        position: absolute;
        z-index: 1;
        bottom: 150%;
        left: 50%;
        margin-left: -75px;
        opacity: 0;
        transition: opacity 0.3s;
    }

    .tooltip .tooltiptext::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: #555 transparent transparent transparent;
    }

    .tooltip:hover .tooltiptext {
        visibility: visible;
        opacity: 1;
    }

    .copy-clipboard {
        border: none;
        border-radius: 5px;
        padding: 5px 10px 5px 10px;
        background-color: #555;
        color: white;
        cursor: pointer;
    }

    .control-div {
        margin-top: 10px;
    }

    .div-xml {
        width: 90%;
        margin-left: 20px;
        font-weight: bold;
        margin-top: 5px;
        padding: 2px;
    }

    .txt-xml {
        border: 1px solid;
        padding: 5px;
        background-color: #ffffff;
    }

    .ck-item {
        position: relative;
    }

    .ck-item:before {
        font-family: "Font Awesome 5 Free";
        content: "\f058";
        float: right;
    }

    .divsXml {
        overflow: auto !important;
        /* height: 500px !important; */
        width: 96%;
        left: 2%;
        background-color: #efefef;
        padding: 10px;
    }

    .divDescription {
        padding: 10px 10px 10px 0px;
        font-size: 1.3em;
    }

    .divDescriptionValidation {
        padding: 10px 10px 10px 10px;
    }

    .divButton {
        padding: 20px;
        display: flex;
        justify-content: flex-end;
    }

    .divMsg {
        display: flex;
        align-items: center;
        color: red;
        font-weight: 800;
    }

    .select-xml {
        display: inline-block;
        font-weight: 800;
        background-color: #ffff00;
    }

    .selected-xml-tag {
        display: inline-block;
        font-weight: 800;
        background-color: #c7c7c7;
    }

    .selected-xml-tag-item {
        position: relative;
    }

    .selected-xml-tag-item span {
        color: inherit !important;
    }

    .selected-xml-tag-item:before {
        font-family: "Font Awesome 5 Free";
        content: "Item já selecionado \f058";
        float: right;
        margin-right: 8px;
    }

    .info-span {
        width: 95%;
    }

    .spinner {
        margin: 100px auto;
        width: 100%;
        height: 40px;
        text-align: center;
        font-size: 10px;
    }

    .spinner>div {
        background-color: #333;
        height: 50px;
        margin-right: 5px;
        width: 6px;
        display: inline-block;
        -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
        animation: sk-stretchdelay 1.2s infinite ease-in-out;
    }

    .spinner .rect2 {
        -webkit-animation-delay: -1.1s;
        animation-delay: -1.1s;
    }

    .spinner .rect3 {
        -webkit-animation-delay: -1.0s;
        animation-delay: -1.0s;
    }

    .spinner .rect4 {
        -webkit-animation-delay: -0.9s;
        animation-delay: -0.9s;
    }

    .spinner .rect5 {
        -webkit-animation-delay: -0.8s;
        animation-delay: -0.8s;
    }

    .content-validation {
        background-color: #efeff0;
    }

    .ul-blacklist {
        width: 100%;
    }
    .ul-blacklist>ul{
        list-style: none;
        margin: 0px 0px 0px -38px;
    }
    .ul-blacklist>ul>li{
        list-style: none;
    }
    .blacklist + .blacklist::before {
        content: ", ";
    }

    @media (max-width: 599px) {
        .control-div {
            word-break: break-all;
        }
    }

    @-webkit-keyframes sk-stretchdelay {

        0%,
        40%,
        100% {
            -webkit-transform: scaleY(0.4)
        }

        20% {
            -webkit-transform: scaleY(1.0)
        }
    }

    @keyframes sk-stretchdelay {

        0%,
        40%,
        100% {
            transform: scaleY(0.4);
            -webkit-transform: scaleY(0.4);
        }

        20% {
            transform: scaleY(1.0);
            -webkit-transform: scaleY(1.0);
        }
    }

</style>
